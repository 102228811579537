export module LocIds {
    export enum Label {
        Audits = 'Label_Audits',
        Auditors = 'Label_Auditors',
        AuditName = 'Label_AuditName',
        AuditReview = 'Label_AuditReview',
        TrustPortal = 'Label_TrustPortal',
        AllTrustPortal = 'Label_AllTrustPortal',
        TrustPortalHomePage = 'Label_TrustPortalHomePage',
        AuditEvent = 'Label_AuditEvent',
        AuditEvents = 'Label_AuditEvents',
        Evidences = 'Label_Evidences',
        RightToAudit = 'Label_RightToAudit',
        AuditEventOwners = 'Label_AuditEventOwners',
        EvidenceRequest = 'Label_EvidenceRequest',
        ExcludeImportedEvidence = 'Label_ExcludeImportedEvidence',
        Certification = 'Label_Certification',
        Certifications = 'Label_Certifications',
        CertificationControl = 'Label_CertificationControl',
        CertificationControls = 'Label_CertificationControls',
        Environment = 'Label_Environment',
        Environments = 'Label_Environments',
        Pipeline = 'Label_Pipeline',
        Pipelines = 'Label_Pipelines',
        Disabled = 'Label_Disabled',
        Enabled = 'Label_Enabled',
        CorrespondingService = 'Label_CorrespondingService',
        Service = 'Label_Service',
        Services = 'Label_Services',
        ProviderService = 'Label_ProviderService',
        ConsumerServices = 'Label_ConsumerServices',
        Owner = 'Label_Owner',
        Workload = 'Label_Workload',
        Champ = 'Label_Champ',
        PointOfContact = 'Label_PointOfContact',
        Tester = 'Label_Tester',
        Na = 'Label_Na',
        On = 'Label_On',
        Of = 'Label_Of',
        Off = 'Label_Off',
        Close = 'Label_Close',
        Ascending = 'Label_Ascending',
        Descending = 'Label_Descending',
        AssignedTo = 'Label_AssignedTo',
        AssignedToMe = 'Label_AssignedToMe',
        AssignedToMyServices = 'Label_AssignedToMyServices',
        AssignedToMyWorkloads = 'Label_AssignedToMyWorkloads',
        All = 'Label_All',
        Accepted = 'Label_Accepted',
        Rejected = 'Label_Rejected',
        Approved = 'Label_Approved',
        InReview = 'Label_InReview',
        Yes = 'Label_Yes',
        No = 'Label_No',
        Name = 'Label_Name',
        StartDate = 'Label_StartDate',
        EndDate = 'Label_EndDate',
        CreatedDate = 'Label_CreatedDate',
        ModifiedDate = 'Label_ModifiedDate',
        Export = 'Label_Export',
        ReviewedDate = 'Label_ReviewedDate',
        DateRange = 'Label_DateRange',
        DueDate = 'Label_DueDate',
        ExpiryDate = 'Label_ExpiryDate',
        ExpiryDays = 'Label_ExpiryDays',
        Title = 'Label_Title',
        New = 'Label_New',
        Other = 'Label_Other',
        General = 'Label_General',
        Configuration = 'Label_Configuration',
        ReuseConfiguration = 'Label_ReuseConfiguration',
        AdoConfiguration = 'Label_AdoConfiguration',
        AipConfiguration = 'Label_AipConfiguration',
        Draft = 'Label_Draft',
        Original = 'Label_Original',
        Reusable = 'Label_Reusable',
        Imported = 'Label_Imported',
        Published = 'Label_Published',
        SortOrder = 'Label_SortOrder',
        SortBy = 'Label_SortBy',
        State = 'Label_State',
        Submitted = 'Label_Submitted',
        Action = 'Label_Action',
        Description = 'Label_Description',
        AllEvidences = 'Label_AllEvidences',
        Attachment = 'Label_Attachment',
        Attachments = 'Label_Attachments',
        Loading = 'Label_Loading',
        Recent = 'Label_Recent',
        Selected = 'Label_Selected',
        Uploading = 'Label_Uploading',
        Company = 'Label_Company',
        Completed = 'Label_Completed',
        InProgress = 'Label_InProgress',
        NotStarted = 'Label_NotStarted',
        Conflict = 'Label_Conflict',
        Contact = 'Label_Contact',
        ContactUs = 'Label_ContactUs',
        BugId = 'Label_BugId',
        ServiceName = 'Label_ServiceName',
        ServiceTreeId = 'Label_ServiceTreeId',
        SecondaryServiceTreeId = 'Label_SecondaryServiceTreeId',
        EvidenceId = 'Label_EvidenceId',
        Resolve = 'Label_Resolve',
        Filters = 'Label_Filters',
        FilterBy = 'Label_FilterBy',
        Messages = 'Label_Messages',
        RequestedBy = 'Label_RequestedBy',
        Review = 'Label_Review',
        ReviewStatus = 'Label_ReviewStatus',
        ReviewType = 'Label_ReviewType',
        RetainedUntil = 'Label_RetainedUntil',
        Status = 'Label_Status',
        DueBy = 'Label_DueBy',
        CreatedBy = 'Label_CreatedBy',
        CreatedOn = 'Label_CreatedOn',
        ModifiedOn = 'Label_ModifiedOn',
        ModifiedBy = 'Label_ModifiedBy',
        InfoTooltip = 'Label_InfoTooltip',
        MoreAction = 'Label_MoreAction',
        MoreItems = 'Label_MoreItems',
        StartAt = 'Label_StartAt',
        Triggered = 'Label_Triggered',
        Progress = 'Label_Progress',
        Permission = 'Label_Permission',
        Permissions = 'Label_Permissions',
        Frequency = 'Label_Frequency',
        ClassificationReview = 'Label_ClassificationReview',
        ReviewDate = 'Label_ReviewDate',
        LinksToReview = 'Label_LinksToReview',
        MyReviews = 'Label_MyReviews',
        Groups = 'Label_Groups',
        None = 'Label_None',
        WorkItem = 'Label_WorkItem',
        WorkItemState = 'Label_WorkItemState',
        SourceType = 'Label_SourceType',
        EvidenceRequestID = 'Label_EvidenceRequestID',
        Justification = 'Label_Justification',
        RejectionReasonPromt = 'Label_RejectionReasonPromt',
        RequestorJustification = 'Label_RequestorJustification',
        ReviewerJustification = 'Label_ReviewerJustification',
        SortingOptions = 'Label_SortingOptions',
        ViewOptions = 'Label_ViewOptions',
        NumberItems = 'Label_NumberItems',
        NumberServices = 'Label_NumberServices',
        NumberOfTotalServices = 'Label_NumberOfTotalServices',
        NumberGroups = 'Label_NumberGroups',
        NumberSelected = 'Label_NumberSelected',
        AccessControl = 'Label_AccessControl',
        AccessControlList = 'Label_AccessControlList',
        Security = 'Label_Security',
        SecurityGroup = 'Label_SecurityGroup',
        SecurityGroups = 'Label_SecurityGroups',
        SecurityGroupsAndUsers = 'Label_SecurityGroupsAndUsers',
        SecurityGroupOrUser = 'Label_SecurityGroupOrUser',
        Resources = 'Label_Resources',
        YourReview = 'Label_YourReview',
        ResourceName = 'Label_ResourceName',
        ReviewAdmin = 'Label_ReviewAdmin',
        AllServices = 'Label_AllServices',
        SelectedServices = 'Label_SelectedServices',
        SelectServices = 'Label_SelectServices',
        SearchService = 'Label_SearchService',
        NewIteration = 'Label_NewIteration',
        NumberEvidenceRequests = 'Label_NumberEvidenceRequests',
        Area = 'Label_Area',
        Iteration = 'Label_Iteration',
        Template = 'Label_Template',
        AZ = 'Label_AZ',
        ZA = 'Label_ZA',
        Board = 'Label_Board',
        Source = 'Label_Source',
        AriaLabelForSelectAllCheckbox = 'Label_AriaLabelForSelectAllCheckbox',
        AriaLabelForSelectionColumn = 'Label_AriaLabelForSelectionColumn',
        CheckButtonAriaLabel = 'Label_CheckButtonAriaLabel',
        NewEnvironment = 'Label_NewEnvironment',
        ShortName = 'Label_ShortName',
        Retain = 'Label_Retain',
        Revoke = 'Label_Revoke',
        Undo = 'Label_Undo',
        DelegateTo = 'Label_DelegateTo',
        Delegate = 'Label_Delegate',
        Cloud = 'Label_Cloud',
        LastReviewed = 'Label_LastReviewed',
        ClaimType = 'Label_ClaimType',
        GroupPath = 'Label_GroupPath',
        UserName = 'Label_UserName',
        AccessType = 'Label_AccessType',
        ResourceID = 'Label_ResourceID',
        Domain = 'Label_Domain',
        AccessVia = 'Label_AccessVia',
        ResourceOwner = 'Label_ResourceOwner',
        CustomRole = 'Label_CustomRole',
        SOCWorkload = 'Label_SOCWorkload',
        SOCWorkloads = 'Label_SOCWorkloads',
        SOCIntro = 'Label_SOCIntro',
        SOCIntros = 'Label_SOCIntros',
        NewSOCIntro = 'Label_NewSOCIntro',
        SOCIntroName = 'Label_SOCIntroName',
        CreateSOCIntro = 'Label_CreateSOCIntro',
        EditSOCIntro = 'Label_EditSOCIntro',
        SOCAuditStatus = 'Label_SOCAuditStatus',
        AllSOCServicesOf = 'Label_AllSOCServicesOf',
        NoSOCService = 'Label_NoSOCService',
        NumberSOCServices = 'Label_NumberSOCServices',
        BulkCreationNeeded = 'Label_BulkCreationNeeded',
        RemoveItemConfirm = 'Label_RemoveItemConfirm',
        ClosePageConfirm = 'Label_ClosePageConfirm',
        SOCService = 'Label_SOCService',
        EditSOCService = 'Label_EditSOCService',
        NewSOCService = 'Label_NewSOCService',
        CreateSOCService = 'Label_CreateSOCService',
        CreateSOCServiceButton = 'Label_CreateSOCServiceButton',
        CreateSOCWorkload = 'Label_CreateSOCWorkload',
        SOCServices = 'Label_SOCServices',
        SOCServiceName = 'Label_SOCServiceName',
        More = 'Label_More',
        GuestUser = 'Label_GuestUser',
        User = 'Label_User',
        Basic = 'Label_Basic',
        Notification = 'Label_Notification',
        Recipients = 'Label_Recipients',
        AdditionalRecipients = 'Label_AdditionalRecipients',
        AdditionalEmailGroups = 'Label_AdditionalEmailGroups',
        TorusEligibilityName = 'Label_TorusEligibilityName',
        TorusEligibilityMember = 'Label_TorusEligibilityMember',
        AccountFullname = 'Label_AccountFullname',
        Retained = 'Label_Retained',
        Revoked = 'Label_Revoked',
        Decision = 'Label_Decision',
        Manager = 'Label_Manager',
        Division = 'Label_Division',
        Organization = 'Label_Organization',
        QuickSearch = 'Label_QuickSearch',
        ServiceGroup = 'Label_ServiceGroup',
        GovClouds = 'Label_GovClouds',
        NumberOfTests = 'Label_NumberOfTests',
        BcdrChamp = 'Label_BcdrChamp',
        AssessmentStatus = 'Label_AssessmentStatus',
        FedRampOverallRenewalStatus = 'Label_FedRampOverallRenewalStatus',
        Year = 'Label_Year',
        NumberOfTestRecords = 'Label_NumberOfTestRecords',
        CurrentCycleStatus = 'Label_CurrentCycleStatus',
        NextTestDate = 'Label_NextTestDate',
        RenewalStatus = 'Label_RenewalStatus',
        RenewalServicesInScope = 'Label_RenewalServicesInScope',
        FedRampRenewalsCompletionPercentage = 'Label_FedRampRenewalsCompletionPercentage',
        ManualGovSubscriptionMapping = 'Label_ManualGovSubscriptionMapping',
        ServicesWithManualOverride = 'Label_ServicesWithManualOverride',
        UpdatedDate = 'Label_UpdatedDate',
        UpdatedBy = 'Label_UpdatedBy',
        KnownGovClouds = 'Label_KnownGovClouds',
        Regions = 'Label_Regions',
        Mt = 'Label_Mt',
        GccMt = 'Label_GccMt',
        DoDMt = 'Label_DoDMt',
        Gcc = 'Label_Gcc',
        GccM = 'Label_GccM',
        GccHigh = 'Label_GccHigh',
        DoD = 'Label_DoD',
        Unclassified = 'Label_Unclassified',
        Total = 'Label_Total',
        LastTestDate = 'Label_LastTestDate',
        TestTitle = 'Label_TestTitle',
        TestResult = 'Label_TestResult',
        TestRegion = 'Label_TestRegion',
        TestType = 'Label_TestType',
        TestLevel = 'Label_TestLevel',
        BcdrLink = 'Label_BcdrLink',
        AdoLink = 'Label_AdoLink',
        S360Link = 'Label_S360Link',
        Counts = 'Label_Counts',
        InReviewItems = 'Label_InReviewItems',
        RevokedItems = 'Label_RevokedItems',
        RetainedItems = 'Label_RetainedItems',
        ProtectionType = 'Label_ProtectionType',
        Reviewed = 'Label_Reviewed',
        RequestedException = 'Label_RequestedException',
        Copied = 'Label_Copied',
        AzureDevOps = 'Label_AzureDevOps',
        Reports = 'Label_Reports',
        From = 'Label_From',
        To = 'Label_To',
        SubmitEvidenceRequest = 'Label_SubmitEvidenceRequest',
        DoNotShowDoubleConfirmAgain = 'Label_DoNotShowDoubleConfirmAgain',
        AuditEventName = 'Label_AuditEventName',
        CertificationId = 'Label_CertificationId',
        CertificationName = 'Label_CertificationName',
        CertificationControlCode = 'Label_CertificationControlCode',
        EnvironmentName = 'Label_EnvironmentName',
        EnvironmentStatus = 'Label_EnvironmentStatus',
        ServiceStatus = 'Label_ServiceStatus',
        Detail = 'Label_Detail',
        RequestedAt = 'Label_RequestedAt',
        Failed = 'Label_Failed',
        PlusMore = 'Label_PlusMore',
        PipelineId = 'Label_PipelineId',
        CronSchedule = 'Label_CronSchedule',
        File = 'Label_File',
        LastOccurrence = 'Label_LastOccurrence',
        NextOccurrence = 'Label_NextOccurrence',
        AttachmentsCount = 'Label_AttachmentsCount',
        Never = 'Label_Never',
        FontSize = 'Label_FontSize',
        Bold = 'Label_Bold',
        Italic = 'Label_Italic',
        Underline = 'Label_Underline',
        Bullets = 'Label_Bullets',
        Numbering = 'Label_Numbering',
        Outdent = 'Label_Outdent',
        Indent = 'Label_Indent',
        Strikethrough = 'Label_Strikethrough',
        RemoveFormatting = 'Label_RemoveFormatting',
        NumberOfImportedEvidence = 'Label_NumberOfImportedEvidence',
        NumberOfPublishedEvidence = 'Label_NumberOfPublishedEvidence',
        Warning = 'Label_Warning',
        Read = 'Label_Read',
        Unread = 'Label_Unread',
        Discussions = 'Label_Discussions',
        DiscussionsWithTitle = 'Label_DiscussionsWithTitle',
        AuditDiscussions = 'Label_AuditDiscussions',
        EvidenceDiscussions = 'Label_EvidenceDiscussions',
        AddComment = 'Label_AddComment',
        Notifications = 'Label_Notifications',
        Settings = 'Label_Settings',
        CoachmarkSettings = 'Label_CoachmarkSettings',
        ReviewsPending = 'Label_ReviewsPending',
        ExceptionInReview = 'Label_ExceptionInReview',
        ResourceReviewed = 'Label_ResourceReviewed',
        AccessReviewed = 'Label_AccessReviewed',
        InValidation = 'Label_InValidation',
        RequestReviewed = 'Label_RequestReviewed',
        ME = 'Label_ME',
        Common = 'Label_Common',
        ActionItemName = 'Label_ActionItemName',
        SubmittedBy = 'Label_SubmittedBy',
        AttestedBy = 'Label_AttestedBy',
        UID = 'Label_UID',
        FileAttached = 'Label_FileAttached',
        NumberFileAttached = 'Label_NumberFileAttached',
        PleaseEnterAValidEmailAddress = 'Label_PleaseEnterAValidEmailAddress',
        SecurityGroupTooltip = 'Label_SecurityGroupTooltip',
        SearchPermission = 'Label_SearchPermission',
        AddNewPermission = 'Label_AddNewPermission',
        ExistingPermissions = 'Label_ExistingPermissions',
        FileSize = 'Label_FileSize',
        DateAttached = 'Label_DateAttached',
        UploadedBy = 'Label_UploadedBy',
        Actions = 'Label_Actions',
        OverrideRejected = 'Label_OverrideRejected',
        OverrideAccepted = 'Label_OverrideAccepted',
        ListOptions = 'Label_ListOptions',
        DecreaseValue = 'Label_DecreaseValue',
        IncreaseValue = 'Label_IncreaseValue',
        UniqueIdentifier = 'Label_UniqueIdentifier',
        Purpose = 'Label_Purpose',
        Link = 'Label_Link',
        ThankYou = 'Label_ThankYou',
        ActionItems = 'Label_ActionItems',
        Scope = 'Label_Scope',
        HideCoachmarks = 'Label_HideCoachmarks',
        EnterFieldValue = 'Label_EnterFieldValue',
        AreYouSure = 'Label_AreYouSure',
        Who = 'Label_Who',
        When = 'Label_When',
        DataEditor = 'Label_DataEditor',
        DataEditorSidebar = 'Label_DataEditorSidebar',
        EditTableAddRowKeyToggle = 'Label_EditTableAddRowKeyToggle',
        ExceptionApprovedAt = 'Label_ExceptionApprovedAt',
    }
    export enum Placeholder {
        NoCommentsYet = 'Placeholder_NoCommentsYet',
        SelectADate = 'Placeholder_SelectADate',
        SelectAnOption = 'Placeholder_SelectAnOption',
        SelectOptions = 'Placeholder_SelectOptions',
        SelectToFilter = 'Placeholder_SelectToFilter',
        Loading = 'Placeholder_Loading',
        LoadingOptions = 'Placeholder_LoadingOptions',
        LoadingServices = 'Placeholder_LoadingServices',
        TypeToFilter = 'Placeholder_TypeToFilter',
        TypeToSearch = 'Placeholder_TypeToSearch',
        TypeNameIDToFilter = 'Placeholder_TypeNameIDToFilter',
        NotSet = 'Placeholder_NotSet',
        NoItems = 'Placeholder_NoItems',
        NoMessages = 'Placeholder_NoMessages',
    }
    export enum Components {
        AddFilter = 'Components_AddFilter',
        AuditEventDetail = 'Components_AuditEventDetail',
        SelectionLimitExceeded = 'Components_SelectionLimitExceeded',
        SearchResults = 'Components_SearchResults',
        NoResultsFound = 'Components_NoResultsFound',
        NoReportSelected = 'Components_NoReportSelected',
        PleaseMakeASelection = 'Components_PleaseMakeASelection',
        OnlyUploadOneFile = 'Components_OnlyUploadOneFile',
        FailedToUploaFile = 'Components_FailedToUploaFile',
        UploadHint = 'Components_UploadHint',
        ViewLess = 'Components_ViewLess',
        SelectAStartDate = 'Components_SelectAStartDate',
        SelectAnEndDate = 'Components_SelectAnEndDate',
        SelectAllItemsOnThisPage = 'Components_SelectAllItemsOnThisPage',
        SortingOptions = 'Components_SortingOptions',
        ViewOptions = 'Components_ViewOptions',
        RetryUpload = 'Components_RetryUpload',
        RemoveTooltip = 'Components_RemoveTooltip',
        EditTooltip = 'Components_EditTooltip',
        RemoveFilter = 'Components_RemoveFilter',
        AllEvidencesSelected = 'Components_AllEvidencesSelected',
        AllEvidencesOnThisPageSelected = 'Components_AllEvidencesOnThisPageSelected',
        AllGroupsSelected = 'Components_AllGroupsSelected',
        AllGroupsOnThisPageSelected = 'Components_AllGroupsOnThisPageSelected',
        DownloadFile = 'Components_DownloadFile',
        CannotUploadFile = 'Components_CannotUploadFile',
        NoServices = 'Components_NoServices',
        NoServicesMatchGivenKeyword = 'Components_NoServicesMatchGivenKeyword',
        RemoveSOCIntroConfirmation = 'Components_RemoveSOCIntroConfirmation',
        ClosePageConfirmation = 'Components_ClosePageConfirmation',
        ChangesNotAppliedDescription = 'Components_ChangesNotAppliedDescription',
        DiscardChangesTitle = 'Components_DiscardChangesTitle',
        DiscardChangesDescription = 'Components_DiscardChangesDescription',
        UnableToDeleteThisSocWorkload = 'Components_UnableToDeleteThisSocWorkload',
        YouCannotDeleteASocWorkloadWithSocServices = 'Components_YouCannotDeleteASocWorkloadWithSocServices',
        ProtectedFile = 'Components_ProtectedFile',
        NotProtectedFile = 'Components_NotProtectedFile',
        NoReportAvailable = 'Components_NoReportAvailable',
        InvalidFileType = 'Components_InvalidFileType',
    }
    export enum Action {
        Delegate = 'Action_Delegate',
        EnterDelegateName = 'Action_EnterDelegateName',
        Approve = 'Action_Approve',
        AddItems = 'Action_AddItems',
        Accept = 'Action_Accept',
        Reject = 'Action_Reject',
        Retain = 'Action_Retain',
        Revoke = 'Action_Revoke',
        Add = 'Action_Add',
        Attach = 'Action_Attach',
        Delete = 'Action_Delete',
        Remove = 'Action_Remove',
        Clear = 'Action_Clear',
        Copy = 'Action_Copy',
        Resync = 'Action_Resync',
        Disable = 'Action_Disable',
        Done = 'Action_Done',
        DontRemove = 'Action_DontRemove',
        Edit = 'Action_Edit',
        EditInAzureDevOps = 'Action_EditInAzureDevOps',
        Refresh = 'Action_Refresh',
        EditServices = 'Action_EditServices',
        CreateNewReviewIteration = 'Action_CreateNewReviewIteration',
        EditReviewIteration = 'Action_EditReviewIteration',
        Enable = 'Action_Enable',
        Publish = 'Action_Publish',
        Unpublish = 'Action_Unpublish',
        DrillDown = 'Action_DrillDown',
        Report = 'Action_Report',
        Search = 'Action_Search',
        Validate = 'Action_Validate',
        ShowMore = 'Action_ShowMore',
        ShowLess = 'Action_ShowLess',
        AddAuditEvent = 'Action_AddAuditEvent',
        AddSecurityGroup = 'Action_AddSecurityGroup',
        AddSecurityGroupOrUser = 'Action_AddSecurityGroupOrUser',
        Cancel = 'Action_Cancel',
        Confirm = 'Action_Confirm',
        Create = 'Action_Create',
        Detail = 'Action_Detail',
        OK = 'Action_OK',
        Save = 'Action_Save',
        SelectAll = 'Action_SelectAll',
        UnselectAll = 'Action_UnselectAll',
        ShowAll = 'Action_ShowAll',
        HideNoSelectionWorkloads = 'Action_HideNoSelectionWorkloads',
        SignOut = 'Action_SignOut',
        Submit = 'Action_Submit',
        SaveAndSubmit = 'Action_SaveAndSubmit',
        Acknowledge = 'Action_Acknowledge',
        ClearSelection = 'Action_ClearSelection',
        Reset = 'Action_Reset',
        ResetForm = 'Action_ResetForm',
        ResetFilters = 'Action_ResetFilters',
        ClearFilters = 'Action_ClearFilters',
        ResetKeyword = 'Action_ResetKeyword',
        Apply = 'Action_Apply',
        Discard = 'Action_Discard',
        CopyLink = 'Action_CopyLink',
        Include = 'Action_Include',
        Exclude = 'Action_Exclude',
        SelectServices = 'Action_SelectServices',
        SelectPermissions = 'Action_SelectPermissions',
        StartProcessingRequest = 'Action_StartProcessingRequest',
        SuccessfullyCopied = 'Action_SuccessfullyCopied',
        ReturnToHome = 'Action_ReturnToHome',
        CopyToClipboard = 'Action_CopyToClipboard',
        Update = 'Action_Update',
        ReviewAndApprove = 'Action_ReviewAndApprove',
        ForceRenew = 'Action_ForceRenew',
        MarkComplete = 'Action_MarkComplete',
        SubmitException = 'Action_SubmitException',
        Comment = 'Action_Comment',
        GoReview = 'Action_GoReview',
        Attest = 'Action_Attest',
        Complete = 'Action_Complete',
        Upload = 'Action_Upload',
        BulkUpload = 'Action_BulkUpload',
        Modify = 'Action_Modify',
        Continue = 'Action_Continue',
        Exit = 'Action_Exit',
        Back = 'Action_Back',
        SaveAndNext = 'Action_SaveAndNext',
        SaveAndExit = 'Action_SaveAndExit',
        AddNewRow = 'Action_AddNewRow',
        SubmitAnotherResponse = 'Action_SubmitAnotherResponse',
        RequestAdditionalInfo = 'Action_RequestAdditionalInfo',
        MarkTestValidForCurrentCycle = 'Action_MarkTestValidForCurrentCycle',
        Skip = 'Action_Skip',
        Next = 'Action_Next',
        ClickHere = 'Action_ClickHere',
        Downloading = 'Action_Downloading',
        Triage = 'Action_Triage',
        View = 'Action_View',
        DismissOnlyForMe = 'Action_DismissOnlyForMe',
        DismissForEveryone = 'Action_DismissForEveryone',
        Paste = 'Action_Paste',
        ContactUs = 'Action_ContactUs',
        Documentation = 'Action_Documentation',
        PreviewToSubmit = 'Action_PreviewToSubmit',
        LearnMore = 'Action_LearnMore',
    }
    export enum Evidence {
        ActionsOfEvidenceRequests = 'Evidence_ActionsOfEvidenceRequests',
        PublishingState = 'Evidence_PublishingState',
        Published = 'Evidence_Published',
        NotPublished = 'Evidence_NotPublished',
        PublishedButModified = 'Evidence_PublishedButModified',
        EvidenceRequests = 'Evidence_EvidenceRequests',
        EvidenceRequestsTabs = 'Evidence_EvidenceRequestsTabs',
        EditEvidenceRequest = 'Evidence_EditEvidenceRequest',
        Import = 'Evidence_Import',
        EditPublish = 'Evidence_EditPublish',
        ImportEvidences = 'Evidence_ImportEvidences',
        Reuse = 'Evidence_Reuse',
        GlobalImport = 'Evidence_GlobalImport',
        ConfiguredImport = 'Evidence_ConfiguredImport',
        EvidenceListIsEmpty = 'Evidence_EvidenceListIsEmpty',
        NoEvidenceMatchKeyword = 'Evidence_NoEvidenceMatchKeyword',
        NewEvidenceRequests = 'Evidence_NewEvidenceRequests',
        SelectAllEvidenceRequests = 'Evidence_SelectAllEvidenceRequests',
        SelectAllGroups = 'Evidence_SelectAllGroups',
        AuditorProposed = 'Evidence_AuditorProposed',
        ProposeEvidence = 'Evidence_ProposeEvidence',
        ProposedEvidence = 'Evidence_ProposedEvidence',
        ProposedEvidences = 'Evidence_ProposedEvidences',
        EvidenceProduced = 'Evidence_EvidenceProduced',
        ShowAuditEventDetails = 'Evidence_ShowAuditEventDetails',
        ShowProposedEvidenceOnly = 'Evidence_ShowProposedEvidenceOnly',
        ViewEvidenceProposalHistory = 'Evidence_ViewEvidenceProposalHistory',
        ProposeEvidenceGuidance = 'Evidence_ProposeEvidenceGuidance',
        ProposeEvidenceRefreshGuidance = 'Evidence_ProposeEvidenceRefreshGuidance',
        ProposedEvidenceUpdated = 'Evidence_ProposedEvidenceUpdated',
        ProposedEvidenceCreated = 'Evidence_ProposedEvidenceCreated',
        EvidenceRequestsAreCreated = 'Evidence_EvidenceRequestsAreCreated',
        SeparateProviderConsumerServices = 'Evidence_SeparateProviderConsumerServices',
    }
    export enum Error {
        LoadError = 'Error_LoadError',
        TryAgainLater = 'Error_TryAgainLater',
        CertificationControlsAreRequired = 'Error_CertificationControlsAreRequired',
        ServicesAreRequired = 'Error_ServicesAreRequired',
        KeyRequired = 'Error_KeyRequired',
        EmailNameIsInvalid = 'Error_EmailNameIsInvalid',
        SomethingWentWrong = 'Error_SomethingWentWrong',
        OopsSomethingWentWrong = 'Error_OopsSomethingWentWrong',
        ContactOncall = 'Error_ContactOncall',
        ContactAuditEventPOC = 'Error_ContactAuditEventPOC',
        NoContentOrPermission = 'Error_NoContentOrPermission',
        ContactAdministrator = 'Error_ContactAdministrator',
        PositiveNumber = 'Error_PositiveNumber',
        NotExistOrNoPermission = 'Error_NotExistOrNoPermission',
        NoPermissionTrustPortal = 'Error_NoPermissionTrustPortal',
        PageNotFound = 'Error_PageNotFound',
        FileTooLarge = 'Error_FileTooLarge',
        RequiredField = 'Error_RequiredField',
        RequiredAudit = 'Error_RequiredAudit',
        RequiredAuditService = 'Error_RequiredAuditService',
        InvalidDate = 'Error_InvalidDate',
        InvalidFormat = 'Error_InvalidFormat',
        DiscussionDisabled = 'Error_DiscussionDisabled',
        InitAppInsWithNullInstrumentationKey = 'Error_InitAppInsWithNullInstrumentationKey',
        AppInsNotInitialized = 'Error_AppInsNotInitialized',
        HasNoCorpNetAccess = 'Error_HasNoCorpNetAccess',
        ContinueByConnectToVPN = 'Error_ContinueByConnectToVPN',
        ContinueByDisableIPv6 = 'Error_ContinueByDisableIPv6',
        DisconnectedFromCorpNetVPN = 'Error_DisconnectedFromCorpNetVPN',
    }
    export enum Time {
        Annually = 'Time_Annually',
        Semiannually = 'Time_Semiannually',
        Quarterly = 'Time_Quarterly',
        Monthly = 'Time_Monthly',
        Weekly = 'Time_Weekly',
        Daily = 'Time_Daily',
        OneTime = 'Time_OneTime',
        Overdue12Months = 'Time_Overdue12Months',
        Overdue6Months = 'Time_Overdue6Months',
        Overdue3Months = 'Time_Overdue3Months',
        Overdue30Days = 'Time_Overdue30Days',
        Upcoming30Days = 'Time_Upcoming30Days',
        Upcoming3Months = 'Time_Upcoming3Months',
        Upcoming6Months = 'Time_Upcoming6Months',
        Upcoming12Months = 'Time_Upcoming12Months',
        Last30Days = 'Time_Last30Days',
        Last3Months = 'Time_Last3Months',
        Last6Months = 'Time_Last6Months',
        Last12Months = 'Time_Last12Months',
        Last18Months = 'Time_Last18Months',
        Last2Years = 'Time_Last2Years',
        Last3Years = 'Time_Last3Years',
        CreatedOn = 'Time_CreatedOn',
    }
    export enum AuditManager {
        ActionsOfTasks = 'AuditManager_ActionsOfTasks',
        ActionsOfAuditEventList = 'AuditManager_ActionsOfAuditEventList',
        ActionsOfContinuousMonitoringTasks = 'AuditManager_ActionsOfContinuousMonitoringTasks',
        AddCustomInvitationMessage = 'AuditManager_AddCustomInvitationMessage',
        AuditCompany = 'AuditManager_AuditCompany',
        AuditEventList = 'AuditManager_AuditEventList',
        AuditPointOfContact = 'AuditManager_AuditPointOfContact',
        AuditEventListActions = 'AuditManager_AuditEventListActions',
        AuditorsDecision = 'AuditManager_AuditorsDecision',
        BasicInformation = 'AuditManager_BasicInformation',
        ChooseCurrentDraftVersion = 'AuditManager_ChooseCurrentDraftVersion',
        ContinuousMonitoring = 'AuditManager_ContinuousMonitoring',
        ConMon = 'AuditManager_ConMon',
        CopyAuditEvent = 'AuditManager_CopyAuditEvent',
        ContactEmail = 'AuditManager_ContactEmail',
        CreateNewEvidenceRequests = 'AuditManager_CreateNewEvidenceRequests',
        CreateNewMonitoringTask = 'AuditManager_CreateNewMonitoringTask',
        EditMonitoringTask = 'AuditManager_EditMonitoringTask',
        CreateASeparateEvidenceRequest = 'AuditManager_CreateASeparateEvidenceRequest',
        EvidenceCreationDetails = 'AuditManager_EvidenceCreationDetails',
        CreationInProgress = 'AuditManager_CreationInProgress',
        EvidenceCreationInProgressBanner = 'AuditManager_EvidenceCreationInProgressBanner',
        EvidenceCreationSuccessBanner = 'AuditManager_EvidenceCreationSuccessBanner',
        CustomInvitationMessage = 'AuditManager_CustomInvitationMessage',
        CustomInvitationMessageCannotBeEmpty = 'AuditManager_CustomInvitationMessageCannotBeEmpty',
        DateAttached = 'AuditManager_DateAttached',
        EditAuditEvent = 'AuditManager_EditAuditEvent',
        EditAnAuditEvent = 'AuditManager_EditAnAuditEvent',
        EditDraftEvidenceRequest = 'AuditManager_EditDraftEvidenceRequest',
        EmailAddress = 'AuditManager_EmailAddress',
        ReuseFromAllEnabled = 'AuditManager_ReuseFromAllEnabled',
        ReuseConfiguration = 'AuditManager_ReuseConfiguration',
        DisableEvidenceConclusion = 'AuditManager_DisableEvidenceConclusion',
        EnableDiscussions = 'AuditManager_EnableDiscussions',
        EnableCanBypassPublishScopeCheck = 'AuditManager_EnableCanBypassPublishScopeCheck',
        EnableAutomaticPublishingOfEvidenceRequests = 'AuditManager_EnableAutomaticPublishingOfEvidenceRequests',
        EnableAdoConfiguration = 'AuditManager_EnableAdoConfiguration',
        EnableAdoConfigurationDescription = 'AuditManager_EnableAdoConfigurationDescription',
        EnableAdoConfigurationDescriptionDisabled = 'AuditManager_EnableAdoConfigurationDescriptionDisabled',
        EnableAipConfiguration = 'AuditManager_EnableAipConfiguration',
        EnableAipConfigurationDescription = 'AuditManager_EnableAipConfigurationDescription',
        EnableAuditorProposal = 'AuditManager_EnableAuditorProposal',
        ExpireAfterAuditEventEndDateDescription = 'AuditManager_ExpireAfterAuditEventEndDateDescription',
        EvidencesHaveBeenPublished = 'AuditManager_EvidencesHaveBeenPublished',
        EvidencesHaveBeenUnpublished = 'AuditManager_EvidencesHaveBeenUnpublished',
        BulkEvidencesPublishFailure = 'AuditManager_BulkEvidencesPublishFailure',
        SingleEvidencePublishFailure = 'AuditManager_SingleEvidencePublishFailure',
        EvidencesImportedSuccessfully = 'AuditManager_EvidencesImportedSuccessfully',
        EvidencesImportedPartialFailure = 'AuditManager_EvidencesImportedPartialFailure',
        FileName = 'AuditManager_FileName',
        FileStatus = 'AuditManager_FileStatus',
        FileSize = 'AuditManager_FileSize',
        FileTitle = 'AuditManager_FileTitle',
        FileType = 'AuditManager_FileType',
        FilterByName = 'AuditManager_FilterByName',
        ImportAllSelectedReuseEvidenceRequests = 'AuditManager_ImportAllSelectedReuseEvidenceRequests',
        SwitchCardView = 'AuditManager_SwitchCardView',
        IncludeAuditors = 'AuditManager_IncludeAuditors',
        IncludeCertificationsEnvironmentsServices = 'AuditManager_IncludeCertificationsEnvironmentsServices',
        IncludeReuseConfigurations = 'AuditManager_IncludeReuseConfigurations',
        IncludeAdoConfigurations = 'AuditManager_IncludeAdoConfigurations',
        IncludeSocIntros = 'AuditManager_IncludeSocIntros',
        IncludeAipConfigurations = 'AuditManager_IncludeAipConfigurations',
        InternalNotes = 'AuditManager_InternalNotes',
        InviteGuestUser = 'AuditManager_InviteGuestUser',
        InviteNewGuestUser = 'AuditManager_InviteNewGuestUser',
        LoadingReuseConfigurations = 'AuditManager_LoadingReuseConfigurations',
        LoadingServiceConfigurations = 'AuditManager_LoadingServiceConfigurations',
        LoadingAdoConfigurationOptions = 'AuditManager_LoadingAdoConfigurationOptions',
        ModificationSavedSuccessfully = 'AuditManager_ModificationSavedSuccessfully',
        ResyncEvidenceRequestsFromAdo = 'AuditManager_ResyncEvidenceRequestsFromAdo',
        ResynchronizingEvidenceRequests = 'AuditManager_ResynchronizingEvidenceRequests',
        EvidenceRequestsResyncSuccessfully = 'AuditManager_EvidenceRequestsResyncSuccessfully',
        ErrorOccursWhenResynchronizingEvidenceRequests = 'AuditManager_ErrorOccursWhenResynchronizingEvidenceRequests',
        NewAuditEvent = 'AuditManager_NewAuditEvent',
        NewSchedule = 'AuditManager_NewSchedule',
        NewerVersionAvailable = 'AuditManager_NewerVersionAvailable',
        OpenAzureDevopsTask = 'AuditManager_OpenAzureDevopsTask',
        Override = 'AuditManager_Override',
        PleaseEnterAValidName = 'AuditManager_PleaseEnterAValidName',
        PleaseEnterAValidShortName = 'AuditManager_PleaseEnterAValidShortName',
        PleaseEnterAValidDescription = 'AuditManager_PleaseEnterAValidDescription',
        PleaseProvideATitleDescriptionForTheFile = 'AuditManager_PleaseProvideATitleDescriptionForTheFile',
        PleaseSelectAtLeastOneCertificationControl = 'AuditManager_PleaseSelectAtLeastOneCertificationControl',
        PleaseSelectAtLeastOneCertification = 'AuditManager_PleaseSelectAtLeastOneCertification',
        PleaseSelectAtLeastOneEnvironment = 'AuditManager_PleaseSelectAtLeastOneEnvironment',
        PleaseSelectAtLeastOneServiceForThisEnvironment = 'AuditManager_PleaseSelectAtLeastOneServiceForThisEnvironment',
        PleaseSelectAtLeastOneService = 'AuditManager_PleaseSelectAtLeastOneService',
        PleaseSelectAtMostOneService = 'AuditManager_PleaseSelectAtMostOneService',
        SingleServiceSelectionTooltip = 'AuditManager_SingleServiceSelectionTooltip',
        PleaseSelectAtLeastOneOption = 'AuditManager_PleaseSelectAtLeastOneOption',
        DescriptionEditingNotSupported = 'AuditManager_DescriptionEditingNotSupported',
        OnlyAzureDevOpsAddedUsersSupported = 'AuditManager_OnlyAzureDevOpsAddedUsersSupported',
        PublishedOrNotPublished = 'AuditManager_PublishedOrNotPublished',
        RemoveEnvironment = 'AuditManager_RemoveEnvironment',
        RemoveFile = 'AuditManager_RemoveFile',
        ResolvingEvidenceConflictStart = 'AuditManager_ResolvingEvidenceConflictStart',
        ReplaceCurrentDraft = 'AuditManager_ReplaceCurrentDraft',
        SaveResolve = 'AuditManager_SaveResolve',
        SaveResolveLater = 'AuditManager_SaveResolveLater',
        SelectAllDraftEvidenceRequests = 'AuditManager_SelectAllDraftEvidenceRequests',
        ServiceAssignments = 'AuditManager_ServiceAssignments',
        ShowActiveOnly = 'AuditManager_ShowActiveOnly',
        ShowImportedEvidences = 'AuditManager_ShowImportedEvidences',
        ShowMergeRequiredOnlyLabel = 'AuditManager_ShowMergeRequiredOnlyLabel',
        ShowReuseEvidences = 'AuditManager_ShowReuseEvidences',
        ShowOnlyItemsWithOriginalAndDraftRequestsOutOfSync = 'AuditManager_ShowOnlyItemsWithOriginalAndDraftRequestsOutOfSync',
        SomethingWentWrong = 'AuditManager_SomethingWentWrong',
        Ungrouped = 'AuditManager_Ungrouped',
        UpdatingEvidenceStart = 'AuditManager_UpdatingEvidenceStart',
        UpdatingEvidenceSucceeded = 'AuditManager_UpdatingEvidenceSucceeded',
        WithAttachments = 'AuditManager_WithAttachments',
        WithoutAttachments = 'AuditManager_WithoutAttachments',
        EditingUnavailableForImportedEvidence = 'AuditManager_EditingUnavailableForImportedEvidence',
        NoTasks = 'AuditManager_NoTasks',
        NoTasksMatchKeyword = 'AuditManager_NoTasksMatchKeyword',
        NoAuditEvents = 'AuditManager_NoAuditEvents',
        NoAuditEventsMatchKeyword = 'AuditManager_NoAuditEventsMatchKeyword',
        SelectToAdd = 'AuditManager_SelectToAdd',
        WorkloadInformationNotAvailable = 'AuditManager_WorkloadInformationNotAvailable',
        InviteGuestUserDescription = 'AuditManager_InviteGuestUserDescription',
        UpdateCurrentDraft = 'AuditManager_UpdateCurrentDraft',
        PublishedDraftEvidenceTooltip = 'AuditManager_PublishedDraftEvidenceTooltip',
        DraftEvidenceMismatchedConfiguration = 'AuditManager_DraftEvidenceMismatchedConfiguration',
        ViewEvidenceGroup = 'AuditManager_ViewEvidenceGroup',
        OriginalEvidenceUpdatedSuccessfully = 'AuditManager_OriginalEvidenceUpdatedSuccessfully',
        OriginalEvidenceSubmittedSuccessfully = 'AuditManager_OriginalEvidenceSubmittedSuccessfully',
        OriginalEvidenceSavedSuccessfully = 'AuditManager_OriginalEvidenceSavedSuccessfully',
        UnpublishEvidenceRequests = 'AuditManager_UnpublishEvidenceRequests',
        UnpublishSelectedEvidence = 'AuditManager_UnpublishSelectedEvidence',
        PublishEvidenceRequests = 'AuditManager_PublishEvidenceRequests',
        PublishSelectedEvidence = 'AuditManager_PublishSelectedEvidence',
        EvidenceRequestPublished = 'AuditManager_EvidenceRequestPublished',
        EvidenceRequestUnpublished = 'AuditManager_EvidenceRequestUnpublished',
        EvidenceRequestWillBePublished = 'AuditManager_EvidenceRequestWillBePublished',
        UnProtectedEvidenceRequestWillBePublished = 'AuditManager_UnProtectedEvidenceRequestWillBePublished',
        EvidenceRequestWillBeUnpublished = 'AuditManager_EvidenceRequestWillBeUnpublished',
        RemoveItemHint = 'AuditManager_RemoveItemHint',
        AddItem = 'AuditManager_AddItem',
        AuditEventCreated = 'AuditManager_AuditEventCreated',
        CreateCopyAuditEvent = 'AuditManager_CreateCopyAuditEvent',
        EditDraftEvidence = 'AuditManager_EditDraftEvidence',
        ClickToDownloadFile = 'AuditManager_ClickToDownloadFile',
        LabelSeparatePerControl = 'AuditManager_LabelSeparatePerControl',
        LabelTriggered = 'AuditManager_LabelTriggered',
        NoReuseRulesConfigured = 'AuditManager_NoReuseRulesConfigured',
        NoServicesConfigured = 'AuditManager_NoServicesConfigured',
        SelectAuditEventsForReuse = 'AuditManager_SelectAuditEventsForReuse',
        AlsoCreateInAdo = 'AuditManager_AlsoCreateInAdo',
        AlsoCreateInAdoDescription = 'AuditManager_AlsoCreateInAdoDescription',
        AlsoCreateInAdoDisabledDescription = 'AuditManager_AlsoCreateInAdoDisabledDescription',
        AlsoCreateInAdoInvalidTemplate = 'AuditManager_AlsoCreateInAdoInvalidTemplate',
        LinkCopied = 'AuditManager_LinkCopied',
        OnboardedContinuousMonitoringSchedules = 'AuditManager_OnboardedContinuousMonitoringSchedules',
        AddNewSchedules = 'AuditManager_AddNewSchedules',
        RemoveSchedules = 'AuditManager_RemoveSchedules',
        ConfigureService = 'AuditManager_ConfigureService',
        CmTaskStatusOn = 'AuditManager_CmTaskStatusOn',
        CmTaskStatusOff = 'AuditManager_CmTaskStatusOff',
        ServicesOnboarded = 'AuditManager_ServicesOnboarded',
        LastTriggerDate = 'AuditManager_LastTriggerDate',
        NextTriggerDate = 'AuditManager_NextTriggerDate',
        ExpireAfterDays = 'AuditManager_ExpireAfterDays',
        ExpireAfterDate = 'AuditManager_ExpireAfterDate',
        ExpireAfterAuditEventEndDate = 'AuditManager_ExpireAfterAuditEventEndDate',
        ConMonScheduleCreationNotice = 'AuditManager_ConMonScheduleCreationNotice',
        ConMonScheduleCreationSuccess = 'AuditManager_ConMonScheduleCreationSuccess',
        ConMonScheduleUpdateSuccess = 'AuditManager_ConMonScheduleUpdateSuccess',
        ConMonScheduleInvalidField = 'AuditManager_ConMonScheduleInvalidField',
        CreationPlatform = 'AuditManager_CreationPlatform',
        EnableAipConfigurationWarning = 'AuditManager_EnableAipConfigurationWarning',
        DisableAipConfigurationWarning = 'AuditManager_DisableAipConfigurationWarning',
        NoServicesAssociatedWithYou = 'AuditManager_NoServicesAssociatedWithYou',
        ServiceAddedToConMonSuccess = 'AuditManager_ServiceAddedToConMonSuccess',
        ServiceRemovedFromConMonSuccess = 'AuditManager_ServiceRemovedFromConMonSuccess',
        SocDraftDisableEditingTooltip = 'AuditManager_SocDraftDisableEditingTooltip',
        ServicesToBeUpdated = 'AuditManager_ServicesToBeUpdated',
        AssignAllTo = 'AuditManager_AssignAllTo',
        AssignAllToDefault = 'AuditManager_AssignAllToDefault',
        AssignToDefaultDescription = 'AuditManager_AssignToDefaultDescription',
        Variables = 'AuditManager_Variables',
        Use = 'AuditManager_Use',
        UseVariables = 'AuditManager_UseVariables',
        UseVariablesDescription = 'AuditManager_UseVariablesDescription',
        AuditEventNameExample = 'AuditManager_AuditEventNameExample',
        CertificationNameExample = 'AuditManager_CertificationNameExample',
        CertificationControlCodeExample = 'AuditManager_CertificationControlCodeExample',
        EnvironmentNameExample = 'AuditManager_EnvironmentNameExample',
        ServiceNameExample = 'AuditManager_ServiceNameExample',
        NoEvidenceCreationRecord = 'AuditManager_NoEvidenceCreationRecord',
        EvidenceCreationHistory = 'AuditManager_EvidenceCreationHistory',
        ViewEvidenceCreationHistory = 'AuditManager_ViewEvidenceCreationHistory',
        IsAuditEventReusable = 'AuditManager_IsAuditEventReusable',
        DisableEvidenceReuseWarning = 'AuditManager_DisableEvidenceReuseWarning',
    }
    export enum AuditReview {
        NoAuditEventListToDisplay = 'AuditReview_NoAuditEventListToDisplay',
        NotAuthorizedToAccessThisAuditEvent = 'AuditReview_NotAuthorizedToAccessThisAuditEvent',
        YouDontHaveAccess = 'AuditReview_YouDontHaveAccess',
        MSAAccountAipWarning = 'AuditReview_MSAAccountAipWarning',
    }
    export enum AdminPanel {
        PublishedEvidenceRequests = 'AdminPanel_PublishedEvidenceRequests',
        ResynchronizeCollection = 'AdminPanel_ResynchronizeCollection',
        Collection = 'AdminPanel_Collection',
        CustomRoles = 'AdminPanel_CustomRoles',
        GuestUserAccess = 'AdminPanel_GuestUserAccess',
        NewCustomRole = 'AdminPanel_NewCustomRole',
        EditCustomRole = 'AdminPanel_EditCustomRole',
        CustomRoleNameCannotBeEmpty = 'AdminPanel_CustomRoleNameCannotBeEmpty',
        CustomRoleDeletionProcessing = 'AdminPanel_CustomRoleDeletionProcessing',
        CustomRoleDeletionSuccess = 'AdminPanel_CustomRoleDeletionSuccess',
        GuestUserAccessDeletionSuccess = 'AdminPanel_GuestUserAccessDeletionSuccess',
        EnvironmentCreated = 'AdminPanel_EnvironmentCreated',
        EnvironmentDeletionProcessing = 'AdminPanel_EnvironmentDeletionProcessing',
        EnvironmentDeletionSuccess = 'AdminPanel_EnvironmentDeletionSuccess',
        PipelineRefreshScheduled = 'AdminPanel_PipelineRefreshScheduled',
        PipelineRefreshRejected = 'AdminPanel_PipelineRefreshRejected',
        PipelineStateToggleSuccess = 'AdminPanel_PipelineStateToggleSuccess',
        PipelineStateToggleRejected = 'AdminPanel_PipelineStateToggleRejected',
        NoPipelineCronScheduleExists = 'AdminPanel_NoPipelineCronScheduleExists',
        SocWorkloadCreated = 'AdminPanel_SocWorkloadCreated',
        SocWorkloadUpdated = 'AdminPanel_SocWorkloadUpdated',
        SocWorkloadDeleted = 'AdminPanel_SocWorkloadDeleted',
        SocServiceCreated = 'AdminPanel_SocServiceCreated',
        SocServiceUpdated = 'AdminPanel_SocServiceUpdated',
        SocServiceDeleted = 'AdminPanel_SocServiceDeleted',
        ActionsOfEnvironmentList = 'AdminPanel_ActionsOfEnvironmentList',
        ActionsOfPipelineList = 'AdminPanel_ActionsOfPipelineList',
        NoGuestUsers = 'AdminPanel_NoGuestUsers',
        NoMatchingGuestUsers = 'AdminPanel_NoMatchingGuestUsers',
        GuestUserAccessDeletionProcessing = 'AdminPanel_GuestUserAccessDeletionProcessing',
        ActionsOfGuestUserList = 'AdminPanel_ActionsOfGuestUserList',
        PleaseSelectASocWorkload = 'AdminPanel_PleaseSelectASocWorkload',
        NoSocServiceExists = 'AdminPanel_NoSocServiceExists',
        DataEditorPageNotAvailable = 'AdminPanel_DataEditorPageNotAvailable',
        DataEditorActionsSuccess = 'AdminPanel_DataEditorActionsSuccess',
        DataEditorActionsDialogTitle = 'AdminPanel_DataEditorActionsDialogTitle',
        DataEditorActionsDialogSubText = 'AdminPanel_DataEditorActionsDialogSubText',
    }
    export enum Application {
        MicrosoftIcon = 'Application_MicrosoftIcon',
        Microsoft = 'Application_Microsoft',
        SignOut = 'Application_SignOut',
        PrivacyNotice = 'Application_PrivacyNotice',
        QuestionMark = 'Application_QuestionMark',
        SignInFailed = 'Application_SignInFailed',
        FoundNoMenu = 'Application_FoundNoMenu',
        ApplicationNoContent = 'Application_ApplicationNoContent',
        Documentation = 'Application_Documentation',
        Feedback = 'Application_Feedback',
        Quickstart = 'Application_Quickstart',
        Notification = 'Application_Notification',
        Question = 'Application_Question',
        Help = 'Application_Help',
        GetHelp = 'Application_GetHelp',
        Version = 'Application_Version',
        FrontendVersion = 'Application_FrontendVersion',
        BackendVersion = 'Application_BackendVersion',
    }
    export enum ComplianceViewer {
        Export = 'ComplianceViewer_Export',
        Screenshot = 'ComplianceViewer_Screenshot',
        ManageColumns = 'ComplianceViewer_ManageColumns',
        NoColumnsSelected = 'ComplianceViewer_NoColumnsSelected',
        WelcomeMessage = 'ComplianceViewer_WelcomeMessage',
        Title = 'ComplianceViewer_Title',
        DeploymentPopulationTitle = 'ComplianceViewer_DeploymentPopulationTitle',
        BuildDetailsTitle = 'ComplianceViewer_BuildDetailsTitle',
        ServiceTreeID = 'ComplianceViewer_ServiceTreeID',
        ServiceTreeName = 'ComplianceViewer_ServiceTreeName',
        DeploymentUnit = 'ComplianceViewer_DeploymentUnit',
        StartTime = 'ComplianceViewer_StartTime',
        SubType = 'ComplianceViewer_SubType',
        AllSubTypes = 'ComplianceViewer_AllSubTypes',
        AllRepositories = 'ComplianceViewer_AllRepositories',
        ServiceNameOrIDFilter = 'ComplianceViewer_ServiceNameOrIDFilter',
        ServiceNameOrIDFilterValue = 'ComplianceViewer_ServiceNameOrIDFilterValue',
        EnterOptionalFilterType = 'ComplianceViewer_EnterOptionalFilterType',
        EnterOptionalFilterTypeValue = 'ComplianceViewer_EnterOptionalFilterTypeValue',
        Actions = 'ComplianceViewer_Actions',
        ApprovedBy = 'ComplianceViewer_ApprovedBy',
        ApprovalTime = 'ComplianceViewer_ApprovalTime',
        BuildId = 'ComplianceViewer_BuildId',
        BuildNumber = 'ComplianceViewer_BuildNumber',
        DeploymentId = 'ComplianceViewer_DeploymentId',
        DeploymentReachTime = 'ComplianceViewer_DeploymentReachTime',
        DeploymentStage = 'ComplianceViewer_DeploymentStage',
        DeploymentStatus = 'ComplianceViewer_DeploymentStatus',
        EscalationTeam = 'ComplianceViewer_EscalationTeam',
        EnvironmentName = 'ComplianceViewer_EnvironmentName',
        ServiceTreeIdColumn = 'ComplianceViewer_ServiceTreeIdColumn',
        ServiceTreeNameColumn = 'ComplianceViewer_ServiceTreeNameColumn',
        Organization = 'ComplianceViewer_Organization',
        Project = 'ComplianceViewer_Project',
        Region = 'ComplianceViewer_Region',
        ReleaseId = 'ComplianceViewer_ReleaseId',
        Repository = 'ComplianceViewer_Repository',
        RequestedBy = 'ComplianceViewer_RequestedBy',
        DeploymentSubType = 'ComplianceViewer_DeploymentSubType',
        ServicePopulationTitle = 'ComplianceViewer_ServicePopulationTitle',
        Asc = 'ComplianceViewer_Asc',
        Desc = 'ComplianceViewer_Desc',
        Workload = 'ComplianceViewer_Workload',
        DeploymentType = 'ComplianceViewer_DeploymentType',
        Environment = 'ComplianceViewer_Environment',
        DeployRing = 'ComplianceViewer_DeployRing',
        AllRings = 'ComplianceViewer_AllRings',
        AllEnvironments = 'ComplianceViewer_AllEnvironments',
        AllDeploymentTypes = 'ComplianceViewer_AllDeploymentTypes',
        AllOrganizations = 'ComplianceViewer_AllOrganizations',
        AllServiceNameOrIdValues = 'ComplianceViewer_AllServiceNameOrIdValues',
        AllEnvironmentNames = 'ComplianceViewer_AllEnvironmentNames',
        CurrentAuditPeriod = 'ComplianceViewer_CurrentAuditPeriod',
        Query = 'ComplianceViewer_Query',
        Service = 'ComplianceViewer_Service',
        SearchForBuildId = 'ComplianceViewer_SearchForBuildId',
        NoItemsFound = 'ComplianceViewer_NoItemsFound',
        MergedTime = 'ComplianceViewer_MergedTime',
        CommitId = 'ComplianceViewer_CommitId',
        ExternalId = 'ComplianceViewer_ExternalId',
        CompliantStatus = 'ComplianceViewer_CompliantStatus',
        CreatedByIdentity = 'ComplianceViewer_CreatedByIdentity',
        ApprovedByIdentity = 'ComplianceViewer_ApprovedByIdentity',
        LastPushedByIdentity = 'ComplianceViewer_LastPushedByIdentity',
        AcceptAndCompletedByIdentity = 'ComplianceViewer_AcceptAndCompletedByIdentity',
        LastSignedOffDateTime = 'ComplianceViewer_LastSignedOffDateTime',
        LastModifiedDateTime = 'ComplianceViewer_LastModifiedDateTime',
        ProofOfPresenceApprovedByIdentity = 'ComplianceViewer_ProofOfPresenceApprovedByIdentity',
        PRLink = 'ComplianceViewer_PRLink',
        Type = 'ComplianceViewer_Type',
        Value = 'ComplianceViewer_Value',
        LinktoEvidenceLogs = 'ComplianceViewer_LinktoEvidenceLogs',
        None = 'ComplianceViewer_None',
        PullRequests = 'ComplianceViewer_PullRequests',
        BuildEvidence = 'ComplianceViewer_BuildEvidence',
        IncorrectIdMessage = 'ComplianceViewer_IncorrectIdMessage',
        NoBuildEvidence = 'ComplianceViewer_NoBuildEvidence',
        NoPullRequests = 'ComplianceViewer_NoPullRequests',
        EnterBuildId = 'ComplianceViewer_EnterBuildId',
        EnterOrg = 'ComplianceViewer_EnterOrg',
        BackToDeployment = 'ComplianceViewer_BackToDeployment',
        BuildWelcomeMessage = 'ComplianceViewer_BuildWelcomeMessage',
        RibbonBuildId = 'ComplianceViewer_RibbonBuildId',
        RibbonBuildNumber = 'ComplianceViewer_RibbonBuildNumber',
        RibbonOrganization = 'ComplianceViewer_RibbonOrganization',
        RibbonProject = 'ComplianceViewer_RibbonProject',
        RibbonRepository = 'ComplianceViewer_RibbonRepository',
        RibbonStartTime = 'ComplianceViewer_RibbonStartTime',
        SomethingWentWrong = 'ComplianceViewer_SomethingWentWrong',
        ServicesFileName = 'ComplianceViewer_ServicesFileName',
        DeploymentsFileName = 'ComplianceViewer_DeploymentsFileName',
        Csv = 'ComplianceViewer_Csv',
        Column = 'ComplianceViewer_Column',
        BuildEvidenceFileName = 'ComplianceViewer_BuildEvidenceFileName',
        PullRequestFileName = 'ComplianceViewer_PullRequestFileName',
        DeploymentUnits = 'ComplianceViewer_DeploymentUnits',
        Services = 'ComplianceViewer_Services',
        DeploymentsFound = 'ComplianceViewer_DeploymentsFound',
        MissingBuildInformationMessage = 'ComplianceViewer_MissingBuildInformationMessage',
        ExportFailed = 'ComplianceViewer_ExportFailed',
        DeploymentToggleLabel = 'ComplianceViewer_DeploymentToggleLabel',
        DeploymentToggleTooltip = 'ComplianceViewer_DeploymentToggleTooltip',
        SelectAll = 'ComplianceViewer_SelectAll',
        Selected = 'ComplianceViewer_Selected',
    }
    export enum Documentation {
        Title = 'Documentation_Title',
        Welcome = 'Documentation_Welcome',
        Empty = 'Documentation_Empty',
        NoAvailableDocs = 'Documentation_NoAvailableDocs',
        InvalidPath = 'Documentation_InvalidPath',
    }
    export enum Gating {
        Project = 'Gating_Project',
        Organization = 'Gating_Organization',
        Repository = 'Gating_Repository',
        OverallCompliantStatus = 'Gating_OverallCompliantStatus',
        BuildId = 'Gating_BuildId',
        DeployableService = 'Gating_DeployableService',
        DeployableServiceType = 'Gating_DeployableServiceType',
        And = 'Gating_And',
        FilterByService = 'Gating_FilterByService',
        FilterByOrganization = 'Gating_FilterByOrganization',
        FilterByProject = 'Gating_FilterByProject',
        FilterByRepository = 'Gating_FilterByRepository',
        FilterByBuildID = 'Gating_FilterByBuildID',
        FilterByOverallCompliantStatus = 'Gating_FilterByOverallCompliantStatus',
        FilterByDeployableServiceType = 'Gating_FilterByDeployableServiceType',
        DeployableServiceTypeLabel = 'Gating_DeployableServiceTypeLabel',
        DeployableServiceLabel = 'Gating_DeployableServiceLabel',
        Title = 'Gating_Title',
        DetailsTitle = 'Gating_DetailsTitle',
        CheckAgain = 'Gating_CheckAgain',
        GatingRecordId = 'Gating_GatingRecordId',
        TriggeredAt = 'Gating_TriggeredAt',
        Overview = 'Gating_Overview',
        History = 'Gating_History',
        GatingId = 'Gating_GatingId',
        Time = 'Gating_Time',
        PullRequestCompliantStatus = 'Gating_PullRequestCompliantStatus',
        BuildEvidenceCompliantStatus = 'Gating_BuildEvidenceCompliantStatus',
        IdError = 'Gating_IdError',
        IdMissingError = 'Gating_IdMissingError',
        DeployableServiceTypeKey = 'Gating_DeployableServiceTypeKey',
        DeploymentEnvironment = 'Gating_DeploymentEnvironment',
        DeploymentRing = 'Gating_DeploymentRing',
        Parameters = 'Gating_Parameters',
        PullRequests = 'Gating_PullRequests',
        BuildEvidence = 'Gating_BuildEvidence',
        PullRequestID = 'Gating_PullRequestID',
        Result = 'Gating_Result',
        RemediationWorkItem = 'Gating_RemediationWorkItem',
        Note = 'Gating_Note',
        Type = 'Gating_Type',
        Run = 'Gating_Run',
        Exists = 'Gating_Exists',
        RelatedPRsNotFound = 'Gating_RelatedPRsNotFound',
        AllPRsCompliant = 'Gating_AllPRsCompliant',
        SDLBuildEvidenceNotRun = 'Gating_SDLBuildEvidenceNotRun',
        SDLBuildEvidenceMissing = 'Gating_SDLBuildEvidenceMissing',
        SDLBuildEvidence = 'Gating_SDLBuildEvidence',
        NoRelatedBuilds = 'Gating_NoRelatedBuilds',
        NoBuildEvidence = 'Gating_NoBuildEvidence',
        PRNeedsToBeApprovedCorrectly = 'Gating_PRNeedsToBeApprovedCorrectly',
        NonCompliantPRMessage = 'Gating_NonCompliantPRMessage',
        Compass = 'Gating_Compass',
    }
    export enum UserAccessReview {
        PeriodReviewStartDate = 'UserAccessReview_PeriodReviewStartDate',
        PeriodReviewEndDate = 'UserAccessReview_PeriodReviewEndDate',
        ReviewStartDate = 'UserAccessReview_ReviewStartDate',
        ClassificationReviewDueDate = 'UserAccessReview_ClassificationReviewDueDate',
        ClassificationReviewDueDateTooltip = 'UserAccessReview_ClassificationReviewDueDateTooltip',
        GroupReviewDueDate = 'UserAccessReview_GroupReviewDueDate',
        ManagerReviewDueDate = 'UserAccessReview_ManagerReviewDueDate',
        ManagerReviewDueDateTooltip = 'UserAccessReview_ManagerReviewDueDateTooltip',
        RevocationReviewDueDate = 'UserAccessReview_RevocationReviewDueDate',
        RevocationReviewDueDateTooltip = 'UserAccessReview_RevocationReviewDueDateTooltip',
        SnapshotWarning = 'UserAccessReview_SnapshotWarning',
        RevocationRemovalWarning = 'UserAccessReview_RevocationRemovalWarning',
        Stage = 'UserAccessReview_Stage',
        DialogSubtext = 'UserAccessReview_DialogSubtext',
        Production = 'UserAccessReview_Production',
        NonProduction = 'UserAccessReview_NonProduction',
        InScope = 'UserAccessReview_InScope',
        NotInScope = 'UserAccessReview_NotInScope',
        ReviewManagement = 'UserAccessReview_ReviewManagement',
        NotStartedReview = 'UserAccessReview_NotStartedReview',
        ClassificationReview = 'UserAccessReview_ClassificationReview',
        ClassificationReviewItems = 'UserAccessReview_ClassificationReviewItems',
        GroupReview = 'UserAccessReview_GroupReview',
        ManagerReview = 'UserAccessReview_ManagerReview',
        RevocationReview = 'UserAccessReview_RevocationReview',
        ManualReview = 'UserAccessReview_ManualReview',
        ExceptionReview = 'UserAccessReview_ExceptionReview',
        MyReviews = 'UserAccessReview_MyReviews',
        TorusEligibilitiesReview = 'UserAccessReview_TorusEligibilitiesReview',
        Type = 'UserAccessReview_Type',
        ResourceType = 'UserAccessReview_ResourceType',
        ResourceName = 'UserAccessReview_ResourceName',
        ShowResourceOwners = 'UserAccessReview_ShowResourceOwners',
        ResourceOwners = 'UserAccessReview_ResourceOwners',
        ResourceId = 'UserAccessReview_ResourceId',
        ResourcePath = 'UserAccessReview_ResourcePath',
        RequestedBy = 'UserAccessReview_RequestedBy',
        ExceptionJustification = 'UserAccessReview_ExceptionJustification',
        RejectionReason = 'UserAccessReview_RejectionReason',
        RevocationReason = 'UserAccessReview_RevocationReason',
        ReviewedBy = 'UserAccessReview_ReviewedBy',
        Tenant = 'UserAccessReview_Tenant',
        TenantId = 'UserAccessReview_TenantId',
        TenantName = 'UserAccessReview_TenantName',
        ServiceName = 'UserAccessReview_ServiceName',
        Account = 'UserAccessReview_Account',
        Permission = 'UserAccessReview_Permission',
        SecurityGroup = 'UserAccessReview_SecurityGroup',
        Path = 'UserAccessReview_Path',
        Notes = 'UserAccessReview_Notes',
        NoResources = 'UserAccessReview_NoResources',
        CatalogTitle = 'UserAccessReview_CatalogTitle',
        GroupStageName = 'UserAccessReview_GroupStageName',
        ManagerStageName = 'UserAccessReview_ManagerStageName',
        RevocationStageName = 'UserAccessReview_RevocationStageName',
        LandingPageTitle = 'UserAccessReview_LandingPageTitle',
        ServiceOrganization = 'UserAccessReview_ServiceOrganization',
        AccessInfoPanelTitle = 'UserAccessReview_AccessInfoPanelTitle',
        ConfirmSaveIteration = 'UserAccessReview_ConfirmSaveIteration',
        AddResource = 'UserAccessReview_AddResource',
        MissingResource = 'UserAccessReview_MissingResource',
        CouldNotFindResources = 'UserAccessReview_CouldNotFindResources',
        ConcludeReviewSuccess = 'UserAccessReview_ConcludeReviewSuccess',
        AssignedToMe = 'UserAccessReview_AssignedToMe',
        ActionRequired = 'UserAccessReview_ActionRequired',
        NewResource = 'UserAccessReview_NewResource',
        InReview = 'UserAccessReview_InReview',
        ExceptionRequested = 'UserAccessReview_ExceptionRequested',
        ExceptionRejected = 'UserAccessReview_ExceptionRejected',
        ExceptionApproved = 'UserAccessReview_ExceptionApproved',
        RequestOverride = 'UserAccessReview_RequestOverride',
        OverrideRequested = 'UserAccessReview_OverrideRequested',
        OverrideRejected = 'UserAccessReview_OverrideRejected',
        OverrideApproved = 'UserAccessReview_OverrideApproved',
        InValidation = 'UserAccessReview_InValidation',
        RevocationInstructionActionRequired = 'UserAccessReview_RevocationInstructionActionRequired',
        RevocationInstruction = 'UserAccessReview_RevocationInstruction',
        MarkRemoved = 'UserAccessReview_MarkRemoved',
        MarkRemovedReminder = 'UserAccessReview_MarkRemovedReminder',
        ValidatingRemoval = 'UserAccessReview_ValidatingRemoval',
        RevocationValidationDurationReminder = 'UserAccessReview_RevocationValidationDurationReminder',
        Validated = 'UserAccessReview_Validated',
        Retained = 'UserAccessReview_Retained',
        Approved = 'UserAccessReview_Approved',
        ApprovedBy = 'UserAccessReview_ApprovedBy',
        Rejected = 'UserAccessReview_Rejected',
        RejectedBy = 'UserAccessReview_RejectedBy',
        RequestRevocation = 'UserAccessReview_RequestRevocation',
        RevocationRequested = 'UserAccessReview_RevocationRequested',
        ClassificationExceptions = 'UserAccessReview_ClassificationExceptions',
        RejectException = 'UserAccessReview_RejectException',
        RejectRevocationException = 'UserAccessReview_RejectRevocationException',
        RevocationExceptions = 'UserAccessReview_RevocationExceptions',
        AccessDetails = 'UserAccessReview_AccessDetails',
        AccessType = 'UserAccessReview_AccessType',
        ManagerRequestedRemoval = 'UserAccessReview_ManagerRequestedRemoval',
        MissingManagerResponse = 'UserAccessReview_MissingManagerResponse',
        UnknownUser = 'UserAccessReview_UnknownUser',
        InactiveUser = 'UserAccessReview_InactiveUser',
        NonMsftUser = 'UserAccessReview_NonMsftUser',
        EmptyIterationHeading = 'UserAccessReview_EmptyIterationHeading',
        EmptyIterationSubheading = 'UserAccessReview_EmptyIterationSubheading',
        EmptyIterationAction = 'UserAccessReview_EmptyIterationAction',
        EmptyServicesHeading = 'UserAccessReview_EmptyServicesHeading',
        EmptyServicesSubheading = 'UserAccessReview_EmptyServicesSubheading',
        EmptyResourcesHeading = 'UserAccessReview_EmptyResourcesHeading',
        EmptyResourcesSubheading = 'UserAccessReview_EmptyResourcesSubheading',
        EmptyResourcesAction = 'UserAccessReview_EmptyResourcesAction',
        EmptyNewIterationHeading = 'UserAccessReview_EmptyNewIterationHeading',
        EmptyNewIterationSubheading = 'UserAccessReview_EmptyNewIterationSubheading',
        EmptyNewIterationAction = 'UserAccessReview_EmptyNewIterationAction',
        EmptyManagerReviewsHeading = 'UserAccessReview_EmptyManagerReviewsHeading',
        EmptyManagerReviewsSubheading = 'UserAccessReview_EmptyManagerReviewsSubheading',
        EmptyGroupReviewsHeading = 'UserAccessReview_EmptyGroupReviewsHeading',
        EmptyGroupReviewsSubheading = 'UserAccessReview_EmptyGroupReviewsSubheading',
        EmptyRevocationReviewsHeading = 'UserAccessReview_EmptyRevocationReviewsHeading',
        EmptyRevocationReviewsSubheading = 'UserAccessReview_EmptyRevocationReviewsSubheading',
        EmptyMyReviewsHeading = 'UserAccessReview_EmptyMyReviewsHeading',
        EmptyMyReviewsSubheading = 'UserAccessReview_EmptyMyReviewsSubheading',
        ShowDetails = 'UserAccessReview_ShowDetails',
        AttestationRequired = 'UserAccessReview_AttestationRequired',
        EvidenceRequired = 'UserAccessReview_EvidenceRequired',
        SkippedAndAttestationRequired = 'UserAccessReview_SkippedAndAttestationRequired',
        SubmittedAndAttestationRequired = 'UserAccessReview_SubmittedAndAttestationRequired',
        SkippedAndCompleted = 'UserAccessReview_SkippedAndCompleted',
        SubmittedAndCompleted = 'UserAccessReview_SubmittedAndCompleted',
        ActionItemsServiceCategoryDescription = 'UserAccessReview_ActionItemsServiceCategoryDescription',
        ActionItemsManuallyAddedCategoryDescription = 'UserAccessReview_ActionItemsManuallyAddedCategoryDescription',
        ActionItemsNoACLsCategoryDescription = 'UserAccessReview_ActionItemsNoACLsCategoryDescription',
        ActionItemsSuccessfullyAddedCategoryDescription = 'UserAccessReview_ActionItemsSuccessfullyAddedCategoryDescription',
        ResourceFound = 'UserAccessReview_ResourceFound',
        ResourceNotFound = 'UserAccessReview_ResourceNotFound',
        ResourceTypePlaceholder = 'UserAccessReview_ResourceTypePlaceholder',
        ResourceNamePlaceholder = 'UserAccessReview_ResourceNamePlaceholder',
        ResourceTenant = 'UserAccessReview_ResourceTenant',
        PermissionsSelected = 'UserAccessReview_PermissionsSelected',
        AccessControlSelected = 'UserAccessReview_AccessControlSelected',
        ManualAttestationPanelTitle = 'UserAccessReview_ManualAttestationPanelTitle',
        ManualEvidenceUploadPanelTitle = 'UserAccessReview_ManualEvidenceUploadPanelTitle',
        ManualConfirmSubmissionWithNoEvidence = 'UserAccessReview_ManualConfirmSubmissionWithNoEvidence',
        ManualConfirmSubmissionDialogTitle = 'UserAccessReview_ManualConfirmSubmissionDialogTitle',
        ResourcesWereIncluded = 'UserAccessReview_ResourcesWereIncluded',
        ResourcesWereExcluded = 'UserAccessReview_ResourcesWereExcluded',
        ResourcesGotRejected = 'UserAccessReview_ResourcesGotRejected',
        ResourcesRaisedException = 'UserAccessReview_ResourcesRaisedException',
        CompleteClassification = 'UserAccessReview_CompleteClassification',
        AttestClassificationModalTitle = 'UserAccessReview_AttestClassificationModalTitle',
        UploadEvidenceForAccessControl = 'UserAccessReview_UploadEvidenceForAccessControl',
        UploadEvidenceForAccessControlDescription = 'UserAccessReview_UploadEvidenceForAccessControlDescription',
        ManualAdded = 'UserAccessReview_ManualAdded',
        Automatic = 'UserAccessReview_Automatic',
        CreationMethod = 'UserAccessReview_CreationMethod',
        ValidationPending = 'UserAccessReview_ValidationPending',
        EmptyNewResourceHeading = 'UserAccessReview_EmptyNewResourceHeading',
        EmptyNewResourceSubheading = 'UserAccessReview_EmptyNewResourceSubheading',
        EmptyNewResourceAction = 'UserAccessReview_EmptyNewResourceAction',
        UpdatedResourceHeading = 'UserAccessReview_UpdatedResourceHeading',
        UpdatedResourceSubheading = 'UserAccessReview_UpdatedResourceSubheading',
        UpdatedResourceAction = 'UserAccessReview_UpdatedResourceAction',
        ConfirmDeleteResource = 'UserAccessReview_ConfirmDeleteResource',
        ConfirmDeleteResourceDesc = 'UserAccessReview_ConfirmDeleteResourceDesc',
        UploadRequiredEvidence = 'UserAccessReview_UploadRequiredEvidence',
        NoPermissions = 'UserAccessReview_NoPermissions',
        SkipManualReviewItem = 'UserAccessReview_SkipManualReviewItem',
        ManualInstructions = 'UserAccessReview_ManualInstructions',
        ManualSubmitAttest = 'UserAccessReview_ManualSubmitAttest',
        PermissionText = 'UserAccessReview_PermissionText',
        DueDateCoachmark = 'UserAccessReview_DueDateCoachmark',
        StatusFilterCoachmark = 'UserAccessReview_StatusFilterCoachmark',
        FiltersCoachmark = 'UserAccessReview_FiltersCoachmark',
        AssignedToCoachmark = 'UserAccessReview_AssignedToCoachmark',
    }
    export enum RevocationReviewPage {
        AssignedToCoachmark = 'RevocationReviewPage_AssignedToCoachmark',
    }
    export enum UserAccessReview {
        BulkActionsCoachmark = 'UserAccessReview_BulkActionsCoachmark',
        IncludeCoachmark = 'UserAccessReview_IncludeCoachmark',
        ExcludeCoachmark = 'UserAccessReview_ExcludeCoachmark',
        DelegateCoachmark = 'UserAccessReview_DelegateCoachmark',
        DocumentationCoachmark = 'UserAccessReview_DocumentationCoachmark',
    }
    export enum MyReviewPage {
        DocumentationCoachmark = 'MyReviewPage_DocumentationCoachmark',
        FiltersCoachmark = 'MyReviewPage_FiltersCoachmark',
    }
    export enum ManagerReviewPage {
        FiltersCoachmark = 'ManagerReviewPage_FiltersCoachmark',
        BulkActionsCoachmark = 'ManagerReviewPage_BulkActionsCoachmark',
    }
    export enum ManualReviewPage {
        BulkActionsCoachmark = 'ManualReviewPage_BulkActionsCoachmark',
    }
    export enum UserAccessReview {
        BulkSelectCoachmark = 'UserAccessReview_BulkSelectCoachmark',
        ActionHintText = 'UserAccessReview_ActionHintText',
        ManualReviewTriage = 'UserAccessReview_ManualReviewTriage',
        TriageFilterRequiredMessage = 'UserAccessReview_TriageFilterRequiredMessage',
        ClassificationReviewTriage = 'UserAccessReview_ClassificationReviewTriage',
        MissingAttachments = 'UserAccessReview_MissingAttachments',
        NewlyIdentified = 'UserAccessReview_NewlyIdentified',
        PreviousExceptionGranted = 'UserAccessReview_PreviousExceptionGranted',
        ShowReviewHistory = 'UserAccessReview_ShowReviewHistory',
        ManagerReviewTriage = 'UserAccessReview_ManagerReviewTriage',
        ExcludeJustificationModalTitle = 'UserAccessReview_ExcludeJustificationModalTitle',
        RevocationTriage = 'UserAccessReview_RevocationTriage',
        RevocationApproveItemsWithExceptionApprovedWarningDialogTitle = 'UserAccessReview_RevocationApproveItemsWithExceptionApprovedWarningDialogTitle',
        RevocationApproveItemsWithExceptionApprovedWarningDialogContent = 'UserAccessReview_RevocationApproveItemsWithExceptionApprovedWarningDialogContent',
    }
    export enum ServiceWorkspace {
        ServiceWorkspace = 'ServiceWorkspace_ServiceWorkspace',
        ShowEnabledOnly = 'ServiceWorkspace_ShowEnabledOnly',
        ShowAllSchedules = 'ServiceWorkspace_ShowAllSchedules',
        NoOnboardedSchedules = 'ServiceWorkspace_NoOnboardedSchedules',
        NoAvailableSchedules = 'ServiceWorkspace_NoAvailableSchedules',
        NoAvailableSchedulesByFilter = 'ServiceWorkspace_NoAvailableSchedulesByFilter',
        SubmitEvidenceRequestDoubleConfirmText = 'ServiceWorkspace_SubmitEvidenceRequestDoubleConfirmText',
        ConfigureNotificationGuideline = 'ServiceWorkspace_ConfigureNotificationGuideline',
        ServiceNotificationConfigUpdated = 'ServiceWorkspace_ServiceNotificationConfigUpdated',
        ChangeAssignee = 'ServiceWorkspace_ChangeAssignee',
        ChangeAssigneeDescription = 'ServiceWorkspace_ChangeAssigneeDescription',
        NewAssignee = 'ServiceWorkspace_NewAssignee',
    }
    export enum FedHound {
        Header = 'FedHound_Header',
        SummaryPage = 'FedHound_SummaryPage',
        DetailedPage = 'FedHound_DetailedPage',
        DetailedPageReviewApprove = 'FedHound_DetailedPageReviewApprove',
        AdminPage = 'FedHound_AdminPage',
        ManualGovSubscriptionMapping = 'FedHound_ManualGovSubscriptionMapping',
        TriggerRenewals = 'FedHound_TriggerRenewals',
        DiscoveryPage = 'FedHound_DiscoveryPage',
        NotInitiated = 'FedHound_NotInitiated',
        StatusInitiated = 'FedHound_StatusInitiated',
        StatusNotInitiated = 'FedHound_StatusNotInitiated',
        StatusCompleted = 'FedHound_StatusCompleted',
        Renewed = 'FedHound_Renewed',
        RenewalsHistory = 'FedHound_RenewalsHistory',
        SharepointVerificationLink = 'FedHound_SharepointVerificationLink',
        RenewalNotes = 'FedHound_RenewalNotes',
        FiscalYear = 'FedHound_FiscalYear',
        NumberOfTestRecords = 'FedHound_NumberOfTestRecords',
        CloseDetailsPage = 'FedHound_CloseDetailsPage',
        ServiceName = 'FedHound_ServiceName',
        Classification = 'FedHound_Classification',
        AZOnly = 'FedHound_AZOnly',
        CrossRegion = 'FedHound_CrossRegion',
        CrossRegionAndCustomerEnabledCombined = 'FedHound_CrossRegionAndCustomerEnabledCombined',
        CustomerEnabledDR = 'FedHound_CustomerEnabledDR',
        DataRecoveryOnly = 'FedHound_DataRecoveryOnly',
        Geographic = 'FedHound_Geographic',
        ManualProcessService = 'FedHound_ManualProcessService',
        MultiRegionStateless = 'FedHound_MultiRegionStateless',
        OneInstance = 'FedHound_OneInstance',
        SingleRegionIsolated = 'FedHound_SingleRegionIsolated',
        SingleRegionWithWorkloads = 'FedHound_SingleRegionWithWorkloads',
        SubService = 'FedHound_SubService',
        ThirdPartyServices = 'FedHound_ThirdPartyServices',
        ParentService = 'FedHound_ParentService',
        LastApprovedAssessmentDate = 'FedHound_LastApprovedAssessmentDate',
        ChangesMayTakeTimeNotice = 'FedHound_ChangesMayTakeTimeNotice',
        TestApprovalCancelled = 'FedHound_TestApprovalCancelled',
        FairfaxLifecycle = 'FedHound_FairfaxLifecycle',
        InitialComplianceOnboarded = 'FedHound_InitialComplianceOnboarded',
        ComplianceOnboardingMT = 'FedHound_ComplianceOnboardingMT',
        ISO = 'FedHound_ISO',
        FedRampMT = 'FedHound_FedRampMT',
        SOC = 'FedHound_SOC',
        BcdrTestInformation = 'FedHound_BcdrTestInformation',
        ServiceTreeInformation = 'FedHound_ServiceTreeInformation',
        FairfaxSubscriptions = 'FedHound_FairfaxSubscriptions',
        Discrepancies = 'FedHound_Discrepancies',
        MissingGovTests = 'FedHound_MissingGovTests',
        MissingGovSubscriptions = 'FedHound_MissingGovSubscriptions',
        MissingCertifications = 'FedHound_MissingCertifications',
        FairfaxLifecycleIssues = 'FedHound_FairfaxLifecycleIssues',
        MissingFairfaxLifecycle = 'FedHound_MissingFairfaxLifecycle',
        TotalTests = 'FedHound_TotalTests',
        PassingTests = 'FedHound_PassingTests',
        GovTests = 'FedHound_GovTests',
        PassingGovTests = 'FedHound_PassingGovTests',
        InitiateMissingGovTests = 'FedHound_InitiateMissingGovTests',
        ServicesWithDiscrepancies = 'FedHound_ServicesWithDiscrepancies',
        SubmittedAndValidatedTests = 'FedHound_SubmittedAndValidatedTests',
        CommunicationInitiated = 'FedHound_CommunicationInitiated',
        TestMarkedAsCompleted = 'FedHound_TestMarkedAsCompleted',
        FailedToMarkTestComplete = 'FedHound_FailedToMarkTestComplete',
        MissingTestsInitiatedSuccessfully = 'FedHound_MissingTestsInitiatedSuccessfully',
        FailedToInitiateMissingTests = 'FedHound_FailedToInitiateMissingTests',
        ForceRenewTestSuccessful = 'FedHound_ForceRenewTestSuccessful',
        FailedToForceRenewTest = 'FedHound_FailedToForceRenewTest',
        ValidateTestSuccessful = 'FedHound_ValidateTestSuccessful',
        FailedToValidateTest = 'FedHound_FailedToValidateTest',
        AcknowledgeAnomaly = 'FedHound_AcknowledgeAnomaly',
        RevokeAcknowledgement = 'FedHound_RevokeAcknowledgement',
        AnomalyAcknowledged = 'FedHound_AnomalyAcknowledged',
        AcknowledgedDate = 'FedHound_AcknowledgedDate',
        AnomalyAcknowledgementNotes = 'FedHound_AnomalyAcknowledgementNotes',
        EnterAnomalyNotes = 'FedHound_EnterAnomalyNotes',
        UpdatedAcknowledgement = 'FedHound_UpdatedAcknowledgement',
        FailedToUpdateAcknowledgement = 'FedHound_FailedToUpdateAcknowledgement',
        ServiceGovSubscriptionsUpdatedSuccessfully = 'FedHound_ServiceGovSubscriptionsUpdatedSuccessfully',
        FailedUpdatedServiceSubscriptions = 'FedHound_FailedUpdatedServiceSubscriptions',
        InitiatedDate = 'FedHound_InitiatedDate',
        InitiatedBy = 'FedHound_InitiatedBy',
        GoToParentServiceDetails = 'FedHound_GoToParentServiceDetails',
        SubServiceSubscriptionsCannotChange = 'FedHound_SubServiceSubscriptionsCannotChange',
        ExclusivelyFairfaxLifecycle = 'FedHound_ExclusivelyFairfaxLifecycle',
        BusinessContinuityLead = 'FedHound_BusinessContinuityLead',
        SelectABCL = 'FedHound_SelectABCL',
        NoTest = 'FedHound_NoTest',
        InDevelopment = 'FedHound_InDevelopment',
        PrivatePreview = 'FedHound_PrivatePreview',
        PublicPreview = 'FedHound_PublicPreview',
        GA = 'FedHound_GA',
        ClosingDown = 'FedHound_ClosingDown',
        Retired = 'FedHound_Retired',
        Compliant = 'FedHound_Compliant',
        CompliantWithGap = 'FedHound_CompliantWithGap',
        NonCompliant = 'FedHound_NonCompliant',
        PostApprovalRejected = 'FedHound_PostApprovalRejected',
        RA = 'FedHound_RA',
        RAExpired = 'FedHound_RAExpired',
        InProgress = 'FedHound_InProgress',
        Approved = 'FedHound_Approved',
        AwaitingApproval = 'FedHound_AwaitingApproval',
        ReadyForSubmission = 'FedHound_ReadyForSubmission',
        Rejected = 'FedHound_Rejected',
        Submitted = 'FedHound_Submitted',
        ValidCurrentCycle = 'FedHound_ValidCurrentCycle',
        Initiated = 'FedHound_Initiated',
        InitiatedManually = 'FedHound_InitiatedManually',
        Expired = 'FedHound_Expired',
        Historical = 'FedHound_Historical',
        PastDue = 'FedHound_PastDue',
        LessThan30Days = 'FedHound_LessThan30Days',
        LessThan60Days = 'FedHound_LessThan60Days',
        LessThan90Days = 'FedHound_LessThan90Days',
        NotApplicable = 'FedHound_NotApplicable',
        Deferrable = 'FedHound_Deferrable',
        ServiceTreeIdLinkToBCDR = 'FedHound_ServiceTreeIdLinkToBCDR',
        GoodSampleForAudit = 'FedHound_GoodSampleForAudit',
        KnownClouds = 'FedHound_KnownClouds',
        NumberOfPassingTestsInCloud = 'FedHound_NumberOfPassingTestsInCloud',
        ServiceRenewalDate = 'FedHound_ServiceRenewalDate',
        DueDateAging = 'FedHound_DueDateAging',
        TestsAvailable = 'FedHound_TestsAvailable',
        KnownGovSubscriptions = 'FedHound_KnownGovSubscriptions',
        AdminActions = 'FedHound_AdminActions',
        ManualGccMtTestValidation = 'FedHound_ManualGccMtTestValidation',
        FailoverRegions = 'FedHound_FailoverRegions',
        SetCloudToGCCMT = 'FedHound_SetCloudToGCCMT',
        RevokeGCCMT = 'FedHound_RevokeGCCMT',
        ShowTestsFullyQualifiedForGCCMT = 'FedHound_ShowTestsFullyQualifiedForGCCMT',
        SuccessfullyUpdatedGCCMT = 'FedHound_SuccessfullyUpdatedGCCMT',
        FailedToUpdateGCCMT = 'FedHound_FailedToUpdateGCCMT',
        Inherited = 'FedHound_Inherited',
        DashboardPage = 'FedHound_DashboardPage',
        ManualAuditParticipantMapping = 'FedHound_ManualAuditParticipantMapping',
        FedRamp = 'FedHound_FedRamp',
        SuccessfullyUpdatedAuditScopes = 'FedHound_SuccessfullyUpdatedAuditScopes',
        FailedToUpdateAuditScopes = 'FedHound_FailedToUpdateAuditScopes',
        KnownParticipant = 'FedHound_KnownParticipant',
        AvailableServices = 'FedHound_AvailableServices',
        UpdateAuditScopes = 'FedHound_UpdateAuditScopes',
        NewOnboarding = 'FedHound_NewOnboarding',
        ServiceTreeId = 'FedHound_ServiceTreeId',
        EligibilityCheck = 'FedHound_EligibilityCheck',
        ServiceType = 'FedHound_ServiceType',
        CertificationsInScope = 'FedHound_CertificationsInScope',
        ServiceIsEligible = 'FedHound_ServiceIsEligible',
        ServiceNotEligibleLifecycle = 'FedHound_ServiceNotEligibleLifecycle',
        ServiceNotEligibleChampmissing = 'FedHound_ServiceNotEligibleChampmissing',
        ServiceNotEligibleServiceType = 'FedHound_ServiceNotEligibleServiceType',
        ServiceNotEligibleComponent = 'FedHound_ServiceNotEligibleComponent',
        PleaseFixServiceTreeMetaData = 'FedHound_PleaseFixServiceTreeMetaData',
    }
    export enum RightToAudit {
        ActionsOfAuditRequestList = 'RightToAudit_ActionsOfAuditRequestList',
        NewAuditRequest = 'RightToAudit_NewAuditRequest',
        EditRequestDetails = 'RightToAudit_EditRequestDetails',
        EditAdditionalFields = 'RightToAudit_EditAdditionalFields',
        AdditionalFields = 'RightToAudit_AdditionalFields',
        RequestDetails = 'RightToAudit_RequestDetails',
        AuditPhase = 'RightToAudit_AuditPhase',
        Details = 'RightToAudit_Details',
        SubmissionStatus = 'RightToAudit_SubmissionStatus',
        AuditRequestGuid = 'RightToAudit_AuditRequestGuid',
        ShowOnlySelectedColumns = 'RightToAudit_ShowOnlySelectedColumns',
        UseCheckboxShowColumns = 'RightToAudit_UseCheckboxShowColumns',
        CompactListDetails = 'RightToAudit_CompactListDetails',
        StandardListDetails = 'RightToAudit_StandardListDetails',
        SearchBox = 'RightToAudit_SearchBox',
        RejectInfo = 'RightToAudit_RejectInfo',
        CreateAuditRequestSuccessfully = 'RightToAudit_CreateAuditRequestSuccessfully',
        UpdateAuditRequestSuccessfully = 'RightToAudit_UpdateAuditRequestSuccessfully',
        RequestAdditionalInfoDescription = 'RightToAudit_RequestAdditionalInfoDescription',
        EmptyAuditRequestList = 'RightToAudit_EmptyAuditRequestList',
        InviteOwnersToEditAuditRequest = 'RightToAudit_InviteOwnersToEditAuditRequest',
        SecurityRoles = 'RightToAudit_SecurityRoles',
        ManageCoRequester = 'RightToAudit_ManageCoRequester',
        RejectReasonDescription = 'RightToAudit_RejectReasonDescription',
        MyPermission = 'RightToAudit_MyPermission',
        R2AOwnerRole = 'RightToAudit_R2AOwnerRole',
        RequesterRole = 'RightToAudit_RequesterRole',
        CoRequesterRole = 'RightToAudit_CoRequesterRole',
        ReaderRole = 'RightToAudit_ReaderRole',
    }
    export enum AuditPhase {
        Ingestion = 'AuditPhase_Ingestion',
        Scoping = 'AuditPhase_Scoping',
        Fieldwork = 'AuditPhase_Fieldwork',
        Plan = 'AuditPhase_Plan',
        Closure = 'AuditPhase_Closure',
        PostAudit = 'AuditPhase_PostAudit',
    }
    export enum Title {
        TrustPortal = 'Title_TrustPortal',
        AuditReview = 'Title_AuditReview',
        AuditManager = 'Title_AuditManager',
        AdminPanel = 'Title_AdminPanel',
        MockAudit = 'Title_MockAudit',
        UAR = 'Title_UAR',
        ServiceWorkspace = 'Title_ServiceWorkspace',
        Documentation = 'Title_Documentation',
        ComplianceViewer = 'Title_ComplianceViewer',
        Gating = 'Title_Gating',
        FedHound = 'Title_FedHound',
        RightToAudit = 'Title_RightToAudit',
        DataScout = 'Title_DataScout',
        RegulationManagement = 'Title_RegulationManagement',
    }
    export enum Symbol {
        DoubleLessThan = 'Symbol_DoubleLessThan',
        ColonAndNonBreakingSpace = 'Symbol_ColonAndNonBreakingSpace',
    }
    export enum EmptyState {
        DashboardHeading = 'EmptyState_DashboardHeading',
        DashboardSubheading = 'EmptyState_DashboardSubheading',
    }
    export enum MockAudit {
        WorkLoadNotAvailable = 'MockAudit_WorkLoadNotAvailable',
        InvalidServiceTreeID = 'MockAudit_InvalidServiceTreeID',
        InvalidService = 'MockAudit_InvalidService',
        DesignatedApproversUpdated = 'MockAudit_DesignatedApproversUpdated',
        Error = 'MockAudit_Error',
        SelectWorkload = 'MockAudit_SelectWorkload',
        SelectServices = 'MockAudit_SelectServices',
        SOCComplianceChamp = 'MockAudit_SOCComplianceChamp',
        AdditionalComplianceContacts = 'MockAudit_AdditionalComplianceContacts',
        Workloads = 'MockAudit_Workloads',
        Workload = 'MockAudit_Workload',
        Services = 'MockAudit_Services',
        Service = 'MockAudit_Service',
        ServiceTreeID = 'MockAudit_ServiceTreeID',
        PlaceHolderServiceTreeID = 'MockAudit_PlaceHolderServiceTreeID',
        RemoveButtonLabel = 'MockAudit_RemoveButtonLabel',
        SelectAll = 'MockAudit_SelectAll',
        TagPickerLabel = 'MockAudit_TagPickerLabel',
        TagPickerPlaceHolder = 'MockAudit_TagPickerPlaceHolder',
        DropdownCustomOptionAll = 'MockAudit_DropdownCustomOptionAll',
        SelectAuditYear = 'MockAudit_SelectAuditYear',
        SelectAuditQuarter = 'MockAudit_SelectAuditQuarter',
        AuditYearLabel = 'MockAudit_AuditYearLabel',
        AuditQuarterLabel = 'MockAudit_AuditQuarterLabel',
        CloseButtonLabel = 'MockAudit_CloseButtonLabel',
        SaveButtonText = 'MockAudit_SaveButtonText',
        CancelButtonText = 'MockAudit_CancelButtonText',
        ClonePanelHeader = 'MockAudit_ClonePanelHeader',
        CloneButtonTitle = 'MockAudit_CloneButtonTitle',
        LoadingSpinnerLabel = 'MockAudit_LoadingSpinnerLabel',
        AdhocDetailListKey = 'MockAudit_AdhocDetailListKey',
        CloneButtonText = 'MockAudit_CloneButtonText',
        DatePickerPlaceHolder = 'MockAudit_DatePickerPlaceHolder',
        DropdownPlaceHolder = 'MockAudit_DropdownPlaceHolder',
        TextFieldPlaceHolder = 'MockAudit_TextFieldPlaceHolder',
        AddAdhocDataEntrySave = 'MockAudit_AddAdhocDataEntrySave',
        ExistingAdhocDataEntrySave = 'MockAudit_ExistingAdhocDataEntrySave',
        CloneServicesSaved = 'MockAudit_CloneServicesSaved',
        CloneServicesExists = 'MockAudit_CloneServicesExists',
        NoWorkloadsServices = 'MockAudit_NoWorkloadsServices',
        SOCComplianceChampValidationError = 'MockAudit_SOCComplianceChampValidationError',
        AdditionalComplianceChampError = 'MockAudit_AdditionalComplianceChampError',
    }
    export enum DataScout {
        DataProfilePageTitle = 'DataScout_DataProfilePageTitle',
        DataProfilePageNote = 'DataScout_DataProfilePageNote',
        DataProfileTabAllAssessments = 'DataScout_DataProfileTabAllAssessments',
        DataProfileTabMyAssessments = 'DataScout_DataProfileTabMyAssessments',
        CreateNewDataProfile = 'DataScout_CreateNewDataProfile',
        SelectServiceName = 'DataScout_SelectServiceName',
        SelectOwner = 'DataScout_SelectOwner',
        SelectServiceTreeID = 'DataScout_SelectServiceTreeID',
        ExportToCSV = 'DataScout_ExportToCSV',
        FeatureName = 'DataScout_FeatureName',
        WorkItemID = 'DataScout_WorkItemID',
        DataProfileCreateNew = 'DataScout_DataProfileCreateNew',
        StartSurvey = 'DataScout_StartSurvey',
        ResumeSurvey = 'DataScout_ResumeSurvey',
        EditViewSurvey = 'DataScout_EditViewSurvey',
        EditProperties = 'DataScout_EditProperties',
        CancelAssignment = 'DataScout_CancelAssignment',
        ActivityLogs = 'DataScout_ActivityLogs',
        FormLabelName = 'DataScout_FormLabelName',
        FormLabelPrimaryServiceTreeID = 'DataScout_FormLabelPrimaryServiceTreeID',
        FormLabelPrimaryServiceName = 'DataScout_FormLabelPrimaryServiceName',
        FormLabelIsAnotherServiceTreeID = 'DataScout_FormLabelIsAnotherServiceTreeID',
        FormLabelTemplateTypes = 'DataScout_FormLabelTemplateTypes',
        FormLabelFeatureName = 'DataScout_FormLabelFeatureName',
        FormLabelWorkItemID = 'DataScout_FormLabelWorkItemID',
        FormPlaceHolderEmail = 'DataScout_FormPlaceHolderEmail',
        FormPlaceHolderServiceTreeID = 'DataScout_FormPlaceHolderServiceTreeID',
        FormPlaceHolderServiceName = 'DataScout_FormPlaceHolderServiceName',
        FormLabelSecondaryServiceTreeID = 'DataScout_FormLabelSecondaryServiceTreeID',
        FormServiceTreeIDNotFoundError = 'DataScout_FormServiceTreeIDNotFoundError',
        FormLabelTooltipPrimaryServiceTreeID = 'DataScout_FormLabelTooltipPrimaryServiceTreeID',
        FormLabelTooltipFeatureName = 'DataScout_FormLabelTooltipFeatureName',
        FormLabelTooltipWorkItemID = 'DataScout_FormLabelTooltipWorkItemID',
        FormLabelTooltipOwner = 'DataScout_FormLabelTooltipOwner',
        DataProfileSavedSuccessfully = 'DataScout_DataProfileSavedSuccessfully',
        DataProfileUpdatedSuccessfully = 'DataScout_DataProfileUpdatedSuccessfully',
        SurveyWelcomeTitle = 'DataScout_SurveyWelcomeTitle',
        SurveyWelcomePurpose = 'DataScout_SurveyWelcomePurpose',
        SurveyWelcomeImportantItemsTitle = 'DataScout_SurveyWelcomeImportantItemsTitle',
        SurveyWelcomeImportantItem1 = 'DataScout_SurveyWelcomeImportantItem1',
        SurveyWelcomeImportantItem2 = 'DataScout_SurveyWelcomeImportantItem2',
        SurveyWelcomeImportantItem3 = 'DataScout_SurveyWelcomeImportantItem3',
        SurveyWelcomeNote = 'DataScout_SurveyWelcomeNote',
        SurveyWelcomeContactUs = 'DataScout_SurveyWelcomeContactUs',
        SurveyLinkToDataProfile = 'DataScout_SurveyLinkToDataProfile',
        SurveyInformation = 'DataScout_SurveyInformation',
        DataSurvey = 'DataScout_DataSurvey',
        ViewSurvey = 'DataScout_ViewSurvey',
        EditSurvey = 'DataScout_EditSurvey',
        WelcomeText = 'DataScout_WelcomeText',
        Description = 'DataScout_Description',
        PrimaryServiceTreeID = 'DataScout_PrimaryServiceTreeID',
        AssessmentScope = 'DataScout_AssessmentScope',
        SurveyCreated = 'DataScout_SurveyCreated',
        SurveyAssignedTo = 'DataScout_SurveyAssignedTo',
        SurveyStarted = 'DataScout_SurveyStarted',
        SurveySubmitted = 'DataScout_SurveySubmitted',
        SurveyEdited = 'DataScout_SurveyEdited',
        SurveyResumed = 'DataScout_SurveyResumed',
        SurveyCancelled = 'DataScout_SurveyCancelled',
        SurveyRemoved = 'DataScout_SurveyRemoved',
        CancelMessage = 'DataScout_CancelMessage',
        CancelAssessmentMessage = 'DataScout_CancelAssessmentMessage',
        RemoveAssessmentMessage = 'DataScout_RemoveAssessmentMessage',
        DataName = 'DataScout_DataName',
        DataNameInfo = 'DataScout_DataNameInfo',
        DataDesc = 'DataScout_DataDesc',
        DataDescInfo = 'DataScout_DataDescInfo',
        SurveyThankYouTitle = 'DataScout_SurveyThankYouTitle',
        SurveyThankYouMessage = 'DataScout_SurveyThankYouMessage',
        SurveyHereLink = 'DataScout_SurveyHereLink',
        SurveyThankYouCompletedDataProfile = 'DataScout_SurveyThankYouCompletedDataProfile',
        SurveyThankYouADOWorkItem = 'DataScout_SurveyThankYouADOWorkItem',
        SurveyThankYouFollowUpMessage = 'DataScout_SurveyThankYouFollowUpMessage',
        SurveyThankYouFeedbackMessage = 'DataScout_SurveyThankYouFeedbackMessage',
        DataProfileBulkUpload = 'DataScout_DataProfileBulkUpload',
        SurveyDownloadBulkUpload = 'DataScout_SurveyDownloadBulkUpload',
        DataProfileUpload = 'DataScout_DataProfileUpload',
        UploadTemplate = 'DataScout_UploadTemplate',
        SyncQuestionnaireTemplate = 'DataScout_SyncQuestionnaireTemplate',
        QuestionnaireTemplateSynced = 'DataScout_QuestionnaireTemplateSynced',
        SurveyRequiredFieldsValidationMessage = 'DataScout_SurveyRequiredFieldsValidationMessage',
        DeleteSurveyConfirmationMessage = 'DataScout_DeleteSurveyConfirmationMessage',
        AttachmentSavedSuccesfully = 'DataScout_AttachmentSavedSuccesfully',
        ExcelTemplateUpload = 'DataScout_ExcelTemplateUpload',
        ExcelTemplateNote = 'DataScout_ExcelTemplateNote',
        SaveSurveyConfirmationMessage = 'DataScout_SaveSurveyConfirmationMessage',
        TrainingVideos = 'DataScout_TrainingVideos',
        MoreInformation = 'DataScout_MoreInformation',
        MoreInformationOnNavigation = 'DataScout_MoreInformationOnNavigation',
        ContactPrivacyManager = 'DataScout_ContactPrivacyManager',
        PartOfM365 = 'DataScout_PartOfM365',
        PartOfOPG = 'DataScout_PartOfOPG',
        AssistanceRelatedToDataScout = 'DataScout_AssistanceRelatedToDataScout',
        CloneExistingSurvey = 'DataScout_CloneExistingSurvey',
        CloneExistingSurveyNote = 'DataScout_CloneExistingSurveyNote',
        CloneConfirmation = 'DataScout_CloneConfirmation',
        SurveyRequiredCellsInfo = 'DataScout_SurveyRequiredCellsInfo',
    }
    export enum Platform {
        InternalApplicationCorpNetIpRestriction = 'Platform_InternalApplicationCorpNetIpRestriction',
        ExternalApplicationCorpNetIpRestriction = 'Platform_ExternalApplicationCorpNetIpRestriction',
    }
    export enum SubProcessor {
        PageTitle = 'SubProcessor_PageTitle',
        CreateSubProcessorRequestSuccessfully = 'SubProcessor_CreateSubProcessorRequestSuccessfully',
        ThankYouMessage = 'SubProcessor_ThankYouMessage',
    }
    export enum OnboardingDependency {
        FormLabelServiceTreeID = 'OnboardingDependency_FormLabelServiceTreeID',
        FormPlaceHolderServiceTreeID = 'OnboardingDependency_FormPlaceHolderServiceTreeID',
        FormPlaceHolderServiceName = 'OnboardingDependency_FormPlaceHolderServiceName',
        FormLabelServiceName = 'OnboardingDependency_FormLabelServiceName',
        FormLabelAzureDependencyService = 'OnboardingDependency_FormLabelAzureDependencyService',
        DropDownPlaceHolder = 'OnboardingDependency_DropDownPlaceHolder',
        FormLabelEnvironmentsDeployed = 'OnboardingDependency_FormLabelEnvironmentsDeployed',
        UserRequestForm = 'OnboardingDependency_UserRequestForm',
        ServiceID = 'OnboardingDependency_ServiceID',
        RingData = 'OnboardingDependency_RingData',
        Determination = 'OnboardingDependency_Determination',
        DeterminationState = 'OnboardingDependency_DeterminationState',
        MissingCertification = 'OnboardingDependency_MissingCertification',
        ServiceTreeErrorMessage = 'OnboardingDependency_ServiceTreeErrorMessage',
        AzureDependencyServiceErrorMessage = 'OnboardingDependency_AzureDependencyServiceErrorMessage',
        EnvironmentsDeployedErrorMessage = 'OnboardingDependency_EnvironmentsDeployedErrorMessage',
        FormServiceTreeIDNotFoundError = 'OnboardingDependency_FormServiceTreeIDNotFoundError',
        SavedSucessMessage = 'OnboardingDependency_SavedSucessMessage',
        DeterminationPageTitle = 'OnboardingDependency_DeterminationPageTitle',
        CertificationPageTitle = 'OnboardingDependency_CertificationPageTitle',
        RequestFormPageTitle = 'OnboardingDependency_RequestFormPageTitle',
        FormLabelCertificationName = 'OnboardingDependency_FormLabelCertificationName',
        FormPlaceHolderCertificationName = 'OnboardingDependency_FormPlaceHolderCertificationName',
        FormPlaceHolderCertificationID = 'OnboardingDependency_FormPlaceHolderCertificationID',
        CertificationCreateMessage = 'OnboardingDependency_CertificationCreateMessage',
        CertificationUpdatedMessage = 'OnboardingDependency_CertificationUpdatedMessage',
        CertificationDeletedMessage = 'OnboardingDependency_CertificationDeletedMessage',
        OperationTypeNotAllowedExcetion = 'OnboardingDependency_OperationTypeNotAllowedExcetion',
        ThankyouPageContent = 'OnboardingDependency_ThankyouPageContent',
        PreviewDeterminationNote = 'OnboardingDependency_PreviewDeterminationNote',
        PreviewDataTableNote = 'OnboardingDependency_PreviewDataTableNote',
    }
    export enum RegulationManagement {
        RegulationHubPageMessage = 'RegulationManagement_RegulationHubPageMessage',
    }
}
