import {
  AthenaTriageFilterField, AthenaTriageFilterType, AthenaTriageFilterValueType
} from '../models/UARModels/ManualReviewTriage';


export const parseTriageFilterFields = (filters: Record<string, any>,
  filterFields: AthenaTriageFilterField[]): any => {
  const filterValue: any = {};
  filterFields.forEach((field: AthenaTriageFilterField) => {
    if (filters[field.FieldName] && filters[field.FieldName].length > 0) {
      switch (field.FilterValueType) {
      case AthenaTriageFilterValueType.Guid:
        if (field.FilterType === AthenaTriageFilterType.Static) {
          filterValue[field.FieldName] = {
            value: filters[field.FieldName][0].Value,
            type: 'guid'
          };
        }

        if (field.FilterType === AthenaTriageFilterType.Dynamic || field.FilterType === AthenaTriageFilterType.DynamicAndConnected) {
          filterValue[field.FieldName] = {
            in: filters[field.FieldName].map((item: any) => ({
              value: item.key,
              type: 'guid'
            })),
          };
        }

        if (field.FilterType === AthenaTriageFilterType.UserSearch) {
          filterValue[field.FieldName] = {
            value: filters[field.FieldName][0].AccountGuid,
            type: 'guid'
          };
        }

        break;
      case AthenaTriageFilterValueType.Integer:
        if (field.FilterType === AthenaTriageFilterType.Static) {
          filterValue[field.FieldName] = Number.parseInt(filters[field.FieldName][0].Value);
        }

        if (field.FilterType === AthenaTriageFilterType.Dynamic || field.FilterType === AthenaTriageFilterType.DynamicAndConnected) {
          filterValue[field.FieldName] = {
            in: filters[field.FieldName].map((item: any) => Number.parseInt(item.key)),
          };
        }

        break;
      case AthenaTriageFilterValueType.String:
      default:
        if (field.FilterType === AthenaTriageFilterType.Static) {
          filterValue[field.FieldName] = filters[field.FieldName][0].Value;
        }

        if (field.FilterType === AthenaTriageFilterType.Dynamic || field.FilterType === AthenaTriageFilterType.DynamicAndConnected) {
          filterValue[field.FieldName] = {
            in: filters[field.FieldName].map((item: any) => item.key),
          };
        }

        break;
      }
    }
  });

  return filterValue;
};
