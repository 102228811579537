import { UarPermissionReview } from './ManagerReview';

import { AzureAdMember } from '../AzureAdMember';
import { UserAccount } from '../Forms/UserAccount';

export enum UarRevocationReason {
    None = 'None',
    UserRequested = 'UserRequested',
    NoActionTaken = 'NoActionTaken',
    UserUnderReviewIsInactive = 'UserUnderReviewIsInactive',
    UserUnderReviewIsUnknown = 'UserUnderReviewIsUnknown',
    UserUnderReviewIsNotInMsftTenant = 'UserUnderReviewIsNotInMsftTenant'
}

export interface UarRevocationReview extends UarPermissionReview {
    PermissionRevocationReviewGuid: string;

    State: UarRevocationReviewState;
    ReviewDate: string;
    UserAccessReviewGuid: string;

    ResourceTypeGuid: string;
    ResourceType: string;
    ResourceIdentifier: string;
    ResourceLink: string;
    ResourceOwners: string[];
    Services: string[];
    PermissionGuid: string;
    RequestedByGuid: string;
    RequestedByDisplayName: null | string;
    RevocationReason: UarRevocationReason;

    ExceptionState: null | UarRevocationReviewExceptionState;
    ExceptionJustification: null | string;
    ExceptionRejectionJustification: null | string;
    ExceptionReviewedByDisplayName: null | string;
    ExceptionReviewedByGuid: null | string;

    IdentityUpn: string;
    IdentityJobTitle: string;
    RequestedByJobTitle: string;
    ReviewedByAccountGuid: string;
    ReviewedByDisplayName: string;

    ExceptionSearchText: string;

    CanDelegate?: boolean;
}

export interface UarRevocationReviewRequest {
    IterationGuid: string;
    DirectReports: UserAccount[];
}

export enum UarRevocationReviewState {
    InReview = 'InReview',
    InValidation = 'InValidation',
    Validated = 'Validated',
    Rejected = 'Rejected',
    // This is FE only state
    ExceptionRequested = 'ExceptionRequested',
    ExceptionRejected = 'ExceptionRejected'
}

export enum UarRevocationReviewExceptionState {
    InReview = 'InReview',
    Approved = 'Approved',
    Rejected = 'Rejected'
}

export enum UarRevocationReviewDecision {
    Approved = 'Approve',
    Rejected = 'Reject'
}

export interface RevocationReviewDecisionBaseItem {
    PermissionRevocationReviewGuid: string;
    SetState: UarRevocationReviewDecision;
}

export interface RevocationReviewExceptionDecisionItem extends RevocationReviewDecisionBaseItem{
    SetExceptionRejectionJustification: string;
}

export interface RevocationReviewDecisionItem extends RevocationReviewDecisionBaseItem {
    SetJustification: string;
}

export interface RevocationReviewDecisionPayload<T = RevocationReviewDecisionItem> {
    userAccessReviewGuid: string;
    setItems: T[]
}

export type RevocationReviewExceptionDecisionPayload = RevocationReviewDecisionPayload<RevocationReviewExceptionDecisionItem>;

export interface DelegateRevocationPermissions {
    userAccessReviewGuid: string;
    setDelegation: {
        DelegatedItemGuids: string[];
        DelegateeObjectId: string;
    }
}

export interface AssignRevocationDecisionPayload {
    Permissions: UarRevocationReview[];
    AssignedTo: AzureAdMember;
}

export interface UarPermissionAccessDetails {
    PathType: string;
    RootObjectGuid: string | null;
    ObjectGuid: string | null;
    ObjectName: string | null;
    ObjectPath: string | null;
    MemberGuid: string;
}

export interface UarPermissionAccessDetailsPayload {
    permissionRevocationReviewGuid : string;
    identityGuid: string;
}

export interface UarRevocationFilters {
    ResourceType?: string;
    State?: string;
    Search?: string;
    UserAccessReviewGuid?: string;
    AssignedTo?: string;
    RevocationReason?: string;
}


export interface UseMakeRevocationDecision {
    loadingApprove: boolean;
    loadingReject: boolean;
    makeDecision: (state: UarRevocationReviewDecision, permissions: UarRevocationReview[], justification?: string) => void;
}
