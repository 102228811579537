/* eslint-disable i18next/no-literal-string */

import { OdataQuery } from 'odata';

import {
  DiscoverySummary,
  FedHoundContactBusinessContinuityLead,
  FedHoundDatasetItem,
  FedHoundPermissions,
  ServiceAnomalyDetails,
  FedHoundServiceDetails,
  ServiceSummary,
  FedHoundServiceTestSummary,
  UpdateTestRenewalStatus,
  FedHoundGovSubscriptionMappingSummary,
  CreateOrUpdateManualGovCloudSubscriptionMappingRequest,
  CreateOrUpdateManualServiceTestCloudMappingRequest,
  ServiceTestForGccMtReview,
  CreateOrUpdateFedHoundServiceAuditScopesRequest,
  FedHoundServiceAuditScopesDetails,
  ServiceEligibilityResult,
} from '../../models';
import {
  CommandResult,
  FedRampApproveTestRenewalCommandParams,
  FedRampManualCloudOverrideSearchParams,
  FedRampMarkTestCompleteCommandParams,
  FedRampSearchManualGovSubscriptionMappingSearchParams,
  FedRampUpdateManualGovSubscriptionMappingCommandParams,
  DefaultGuid,
  SortOrderValues,
  FedRampServiceWithManualOverrideItemPagedListResults,
  FedRampForceRenewTestsRequest,
  FedRampValidateTestCommandParams,
  CreateOrUpdateAnomalyAcknowledgement,
} from '../../models/M365BcmToolModels';

import { sortingOrder } from '../../utils';
import {
  get,
  post
} from '../OdataApiService';
import {
  buildOrderByString,
  fieldMatchOnly,
} from '../utils/odataQueryBuilder';

export const GET_FEDHOUND_CONTACT_BUSINESS_CONTINUITY_LEAD = '/data/FedHoundContactBusinessContinuityLead';
export const GET_FEDHOUND_DATASET = '/data/GetFedHoundDatasetRequest';
export const GET_FEDHOUND_PERMISSIONS = '/data/GetFedHoundPermissionsRequest';
export const GET_FEDRAMP_MANUAL_GOV_CLOUD_MAPPINGS = '/data/FedRampGetManualGovCloudMappingRequest';
export const GET_FEDRAMP_SEARCH_MANUAL_GOV_CLOUD_MAPPING = '/data/FedRampGetManualGovCloudMappingSearchRequest';
export const GET_SERVICE_SUMMARY = '/data/GetServiceSummary';
export const GET_SERVICES_VALID_FOR_AUDIT = '/data/GetServicesValidForAudit';
export const GET_FEDHOUND_DISCOVERY_SUMMARY = '/data/FedHoundDiscoverySummary';
export const GET_FEDHOUND_SERVICE_DETAILS = '/data/FedHoundServiceDetails';
export const GET_FEDHOUND_SERVICE_ANOMALY_DETAILS = '/data/FedHoundServiceAnomalyDetails';
export const GET_FEDHOUND_SERVICE_TEST_SUMMARY = '/data/FedHoundServiceTestSummary';
export const GET_FEDHOUND_GOV_SUBSCRIPTION_MAPPING_SUMMARY = '/data/FedHoundGovSubscriptionMappingSummary';
export const GET_SERVICE_TEST_FOR_GCCMT_REVIEW = '/data/ServiceTestForGccMtReview';
export const GET_FEDHOUND_SERVICE_AUDIT_SCOPES_DETAILS = '/data/FedHoundServiceAuditScopesDetails';
export const GET_SERVICE_ELIGIBILITY_RESULT = '/data/ServiceEligibilityResult';

export const UPDATE_TEST_RENEWAL_STATUS = '/data/UpdateTestRenewalStatus';

export const MARK_TEST_VALID = '/data/FedHoundMarkTestValidRequest';
export const FORCE_RENEW_FEDRAMP_TESTS = '/data/FedRampForceRenewTestsRequest';
export const MARK_TEST_COMPLETE = '/data/FedRampMarkTestCompleteRequest';
export const APPROVE_TEST_RENEWAL = '/data/FedRampApproveTestRenewalRequest';
export const UPDATE_FEDRAMP_MANUAL_GOV_CLOUD_MAPPING = '/data/FedRampUpdateManualGovCloudMappingRequest';
export const CREATE_OR_UPDATE_ANOMALY_ACKNOWLEDGEMENT = '/data/CreateOrUpdateAnomalyAcknowledgement';
export const CREATE_OR_UPDATE_MANUAL_GOV_CLOUD_SUBSCRIPTION_MAPPING = '/data/CreateOrUpdateManualGovCloudSubscriptionMappingRequest';
export const CREATE_OR_UPDATE_MANUAL_SERVICE_TEST_CLOUD_MAPPING = '/data/CreateOrUpdateManualServiceTestCloudMappingRequest';
export const CREATE_OR_UPDATE_FEDHOUND_SERVICE_AUDIT_SCOPES =
  '/data/CreateOrUpdateFedHoundServiceAuditScopesRequest';

export const M365BcmToolApi = {
  async getFedHoundContactBusinessContinuityLeadAsync(): Promise<FedHoundContactBusinessContinuityLead[]> {
    const odataQuery: OdataQuery = {
      $orderby: buildOrderByString(('DisplayName' + ' ' + sortingOrder.ASCENDING))
    };
    const config = {
      odataQuery,
    };
    const data = await get<FedHoundContactBusinessContinuityLead[]>(GET_FEDHOUND_CONTACT_BUSINESS_CONTINUITY_LEAD, config);
    return data;
  },

  async getServiceSummaryAsync(bclAccountId: string): Promise<ServiceSummary[]> {
    const searchParams = {
      businessContinuityLeadAccountGuid: bclAccountId
    };
    const data = await post<any, ServiceSummary[]>(GET_SERVICE_SUMMARY, searchParams);
    return data;
  },

  async getServicesValidForAuditAsync(bclAccountId: string): Promise<string[]> {
    const searchParams = {
      businessContinuityLeadAccountGuid: bclAccountId
    };
    const data = await post<any, string[]>(GET_SERVICES_VALID_FOR_AUDIT, searchParams);
    return data;
  },

  async getDiscoverySummaryAsync(
    businessContinuityLeadAccountGuid: string,
  ): Promise<DiscoverySummary[]> {
    const odataQuery: OdataQuery = {
      $filter: fieldMatchOnly(
        {
          value: businessContinuityLeadAccountGuid,
          type: 'guid'
        },
        'BusinessContinuityLeadAccountGuid',
      ),
    };
    const config = {
      odataQuery,
    };
    const data = await get<DiscoverySummary[]>(GET_FEDHOUND_DISCOVERY_SUMMARY, config);
    return data;
  },

  async getServiceDetailsAsync(
    serviceTreeId: string,
  ): Promise<FedHoundServiceDetails> {
    const odataQuery: OdataQuery = {
      $filter: fieldMatchOnly(
        {
          value: serviceTreeId,
          type: 'guid'
        },
        'ServiceTreeId',
      ),
    };
    const config = {
      odataQuery,
    };
    const data = await get<FedHoundServiceDetails[]>(GET_FEDHOUND_SERVICE_DETAILS, config);
    return data[0];
  },

  async getServiceAnomalyDetailsAsync(
    serviceTreeId: string,
  ): Promise<ServiceAnomalyDetails | undefined> {
    const odataQuery: OdataQuery = {
      $filter: fieldMatchOnly(
        {
          value: serviceTreeId,
          type: 'guid'
        },
        'ServiceTreeId',
      ),
    };
    const config = {
      odataQuery,
    };
    const data = await get<ServiceAnomalyDetails[]>(GET_FEDHOUND_SERVICE_ANOMALY_DETAILS, config);
    return data[0];
  },

  async getServiceTestSummaryAsync(
    serviceTreeId: string,
  ): Promise<FedHoundServiceTestSummary[]> {
    const odataQuery: OdataQuery = {
      $filter: fieldMatchOnly(
        {
          value: serviceTreeId,
          type: 'guid'
        },
        'ServiceTreeId',
      ),
    };
    const config = {
      odataQuery,
    };
    const data = await get<FedHoundServiceTestSummary[]>(GET_FEDHOUND_SERVICE_TEST_SUMMARY, config);
    return data;
  },

  async getFedHoundGovSubscriptionMappingSummaryAsync(
    businessContinuityLeadAccountGuid: string,
  ): Promise<FedHoundGovSubscriptionMappingSummary[]> {
    const odataQuery: OdataQuery = {
      $filter: fieldMatchOnly(
        {
          value: businessContinuityLeadAccountGuid,
          type: 'guid'
        },
        'BusinessContinuityLeadAccountGuid',
      ),
    };
    const config = {
      odataQuery,
    };
    const data = await get<FedHoundGovSubscriptionMappingSummary[]>(GET_FEDHOUND_GOV_SUBSCRIPTION_MAPPING_SUMMARY, config);
    return data;
  },

  async getServiceTestForGccMtReviewAsync(
    businessContinuityLeadAccountGuid: string,
  ): Promise<ServiceTestForGccMtReview[]> {
    const odataQuery: OdataQuery = {
      $filter: fieldMatchOnly(
        {
          value: businessContinuityLeadAccountGuid,
          type: 'guid'
        },
        'BusinessContinuityLeadAccountGuid',
      ),
    };
    const config = {
      odataQuery,
    };
    const data = await get<ServiceTestForGccMtReview[]>(GET_SERVICE_TEST_FOR_GCCMT_REVIEW, config);
    return data;
  },

  async getFedHoundServiceAuditScopesDetailsAsync(
    businessContinuityLeadAccountGuid: string,
  ): Promise<FedHoundServiceAuditScopesDetails[]> {
    const odataQuery: OdataQuery = {
      $filter: fieldMatchOnly(
        {
          value: businessContinuityLeadAccountGuid,
          type: 'guid'
        },
        'BusinessContinuityLeadAccountGuid',
      ),
    };
    const config = {
      odataQuery,
    };
    const data = await get<FedHoundServiceAuditScopesDetails[]>(GET_FEDHOUND_SERVICE_AUDIT_SCOPES_DETAILS, config);
    return data;
  },

  //Once the API that processes the ID and returns the eligibility results is complete it will be implemented in this method
  async getServiceEligibilityResultAsync(
    serviceTreeId: string,
  ): Promise<ServiceEligibilityResult> {
    // const odataQuery: OdataQuery = {
    //   $filter: fieldMatchOnly(
    //     {
    //       value: serviceTreeId,
    //       type: 'guid'
    //     },
    //     'ServiceTreeId',
    //   ),
    // };
    // const config = {
    //   odataQuery,
    // };
    // const data = await get<ServiceEligibilityResults>(GET_SERVICE_ELIGIBILITY_RESULT, config);
    // return data;
    const exampleEligibilityResult: ServiceEligibilityResult = {
      ServiceTreeId: serviceTreeId,
      Name: 'Example Service Name',
      ServiceType: 'Online Service',
      Environment: 'GA',
      FairfaxSubscriptions: 'GCC, DoD',
      Certifications: 'SOC, GCC',
      EligibilityResult: 'Eligible'
    };
    return exampleEligibilityResult;
  },

  async getFedHoundDatasetAsync(): Promise<FedHoundDatasetItem[]> {
    const data = await post<Record<string, unknown>,
    FedHoundDatasetItem[]>(GET_FEDHOUND_DATASET, {});
    return data;
  },

  async getFedHoundPermissionsAsync(): Promise<FedHoundPermissions> {
    const data = await post<Record<string, unknown>,
    FedHoundPermissions>(GET_FEDHOUND_PERMISSIONS, {});
    return data;
  },

  async getFedRampManualGovSubscriptionMappingsAsync(quickSearch?: string): Promise<FedRampServiceWithManualOverrideItemPagedListResults> {
    const searchParams: FedRampManualCloudOverrideSearchParams = {
      quickSearch: quickSearch ?? DefaultGuid.DefaultGuid,
      pageIndex: 1,
      pageSize: 1000,
      sortBy: 'UpdatedDate',
      sortOrder: SortOrderValues.Descending
    };
    const data = await post<FedRampManualCloudOverrideSearchParams,
    FedRampServiceWithManualOverrideItemPagedListResults>(GET_FEDRAMP_MANUAL_GOV_CLOUD_MAPPINGS, searchParams);
    return data;
  },

  async getFedRampSearchServiceForManualGovSubscriptionMappingAsync(searchParam: FedRampSearchManualGovSubscriptionMappingSearchParams):
  Promise<FedRampServiceWithManualOverrideItemPagedListResults> {
    const data = await post<FedRampSearchManualGovSubscriptionMappingSearchParams,
    FedRampServiceWithManualOverrideItemPagedListResults>(GET_FEDRAMP_SEARCH_MANUAL_GOV_CLOUD_MAPPING, searchParam);
    return data;
  },

  async updateFedRampManualGovSubscriptionMappingCommandAsync(commandParam: FedRampUpdateManualGovSubscriptionMappingCommandParams):
  Promise<CommandResult> {
    const data = await post<FedRampUpdateManualGovSubscriptionMappingCommandParams,
    CommandResult>(UPDATE_FEDRAMP_MANUAL_GOV_CLOUD_MAPPING, commandParam);
    return data;
  },

  async updateTestRenewalStatusAsync(commandParam: UpdateTestRenewalStatus):
  Promise<void> {
    return await post<UpdateTestRenewalStatus, void>(UPDATE_TEST_RENEWAL_STATUS, commandParam);
  },

  async markTestValidAsync(commandParam: FedRampValidateTestCommandParams):
  Promise<void> {
    return await post<FedRampValidateTestCommandParams, void>(MARK_TEST_VALID, commandParam);
  },

  async forceRenewTestsAsync(commandParam: FedRampForceRenewTestsRequest):
  Promise<void> {
    return await post<FedRampForceRenewTestsRequest, void>(FORCE_RENEW_FEDRAMP_TESTS, commandParam);
  },

  async markTestCompleteCommandAsync(commandParam: FedRampMarkTestCompleteCommandParams):
  Promise<void> {
    const response = await post<FedRampMarkTestCompleteCommandParams, void>(MARK_TEST_COMPLETE, commandParam);
    return response;
  },

  async approveTestRenewalCommandAsync(commandParam: FedRampApproveTestRenewalCommandParams):
  Promise<CommandResult> {
    const data = await post<FedRampApproveTestRenewalCommandParams,
    CommandResult>(APPROVE_TEST_RENEWAL, commandParam);
    return data;
  },

  async createOrUpdateAnomalyAcknowledgementAsync(commandParam: CreateOrUpdateAnomalyAcknowledgement):
  Promise<void> {
    return await post<CreateOrUpdateAnomalyAcknowledgement, void>(CREATE_OR_UPDATE_ANOMALY_ACKNOWLEDGEMENT, commandParam);
  },

  async createOrUpdateManualGovCloudSubscriptionMappingAsync(commandParam: CreateOrUpdateManualGovCloudSubscriptionMappingRequest):
  Promise<void> {
    return await post<CreateOrUpdateManualGovCloudSubscriptionMappingRequest, void>(
      CREATE_OR_UPDATE_MANUAL_GOV_CLOUD_SUBSCRIPTION_MAPPING,
      commandParam);
  },

  async createOrUpdateManualServiceTestCloudMappingAsync(commandParam: CreateOrUpdateManualServiceTestCloudMappingRequest):
  Promise<void> {
    return await post<CreateOrUpdateManualServiceTestCloudMappingRequest, void>(
      CREATE_OR_UPDATE_MANUAL_SERVICE_TEST_CLOUD_MAPPING,
      commandParam);
  },

  async createOrUpdateFedHoundServiceAuditScopesAsync(
    commandParam: CreateOrUpdateFedHoundServiceAuditScopesRequest):
  Promise<void> {
    return await post<CreateOrUpdateFedHoundServiceAuditScopesRequest, void>(
      CREATE_OR_UPDATE_FEDHOUND_SERVICE_AUDIT_SCOPES,
      commandParam);
  },
};
