import { AzureAdMember } from '../AzureAdMember';
import { UserAccount } from '../Forms/UserAccount';

export interface UarResourcePath {
    ResourcePath: string;
}

export enum UarReviewDecision {
    NoAction = 'NoAction',
    Approve = 'Approve',
    Revoke = 'Revoke'
}

export interface UarPermissionReviewBase {
    UserPermissionReviewGuid: string;
}

export interface UarPermissionReview extends UarPermissionReviewBase, UarResourcePath {
    ResourceName: string;
    ResourceGuid: string;
    IdentityGuid: string;
    IdentityDisplayName: string;
    Permission: string;
    AssignToAccountGuid: string | null;
    AssignToDisplayName: string | null;
    ReviewedByAccountGuid: string | null;
    ReviewedByDisplayName: string;
}

export interface UarManagerReview extends UarPermissionReview {
    Decision: UarReviewDecision | null;
    canDelegate?: boolean
}

export interface UarManagerReviewRequest {
    IterationGuid: string;
    DirectReports: UserAccount[];
}

export interface AssignPermissionsPayload {
    Permissions: UarManagerReview[];
    AssignedTo: AzureAdMember;
}

export interface DelegateUserPermissions {
    userAccessReviewGuid: string;
    setDelegation: {
        DelegatedItemGuids: string[];
        DelegateeObjectId: string;
    }
}

export interface ManagerReviewDecisionItem {
    UserPermissionReviewGuid: string;
    SetState: UarReviewDecision;
}

export interface ManagerReviewDecisionPayload {
    userAccessReviewGuid: string;
    setItems: ManagerReviewDecisionItem[]
}

export interface UarReviewFilters {
    Search?: string;
    Decision?: string;
    AssignedTo?: string;
    IdentityGuid?: string;
    Permission?: string;
}
