import _ from 'lodash';
import intl from 'react-intl-universal';

import { LocIds } from '../../../common/Globalization/IntlEnum';
import {
  IApplication, IPNetworkType
} from '../../../models';
import { useMyNetworkStatusSelector } from '../../../redux/networkStatusSlice/selector';
import { useNotification } from '../../../utils';

export const useNetworkStatusNotification = (currentApplication: IApplication | undefined, menuId: string) => {
  const networkStatus = useMyNetworkStatusSelector();
  const hasNoCorpNetAccess = !_.isNil(networkStatus) && !networkStatus.HasCorpNetAccess &&
  !networkStatus.IsLocal && !networkStatus.IsConnectingWithIPv6;

  const currentApplicationMenus = currentApplication?.Menus ?? [];
  const currentMenu = currentApplicationMenus.find(m => m.MenuId === menuId);
  const isInternalMenu = currentMenu?.AllowedIPNetworkTypes?.includes(IPNetworkType.CorpNetPublic) ?? false;

  const showNotification = hasNoCorpNetAccess && isInternalMenu;

  const message = intl.get(LocIds.Error.DisconnectedFromCorpNetVPN);

  useNotification(message, message, showNotification, false);
};
