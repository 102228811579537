import React, { useEffect } from 'react';

import _ from 'lodash';
import intl from 'react-intl-universal';

import { useParams } from 'react-router-dom';

import { ScheduleBoard } from './ScheduleBoard';

import { LocIds } from '../../../../../../common/Globalization/IntlEnum';
import { PageLayout } from '../../../../../../components/templates/PageLayout';
import { ViewLayout } from '../../../../../../components/TrustLayout';
import { UseAsyncHookStatus } from '../../../../../../utils/react-hooks/useAsync';
import { useBreadcrumbItems } from '../../../../hooks/useBreadcrumbItems';
import { useAppDispatch } from '../../redux/reducer';
import {
  clearScheduleService,
  fetchServiceDetailById,
  useLoadingScheduleServiceStatus,
  useServiceOfScheduleBoard,
} from '../../redux/serviceSlice';


export const ScheduleBoardPage: React.FC = () => {
  const { serviceId } = useParams<{ serviceId: string }>();
  const dispatch = useAppDispatch();

  const service = useServiceOfScheduleBoard();
  const loadingScheduleServiceStatus = useLoadingScheduleServiceStatus();

  const breadCrumbItems = useBreadcrumbItems(service);

  useEffect(() => {
    if (!_.isNil(serviceId)) {
      dispatch(fetchServiceDetailById({
        serviceTreeId: serviceId
      }));
    }

    return () => {
      dispatch(clearScheduleService());
    };
  }, []);

  const bodyJsx = (
    <ViewLayout
      isError={loadingScheduleServiceStatus === UseAsyncHookStatus.Fail}
      isLoaded={loadingScheduleServiceStatus === UseAsyncHookStatus.Success}
      isNotFound={_.isNil(service?.ServiceTreeId)}>
      { service?.ServiceTreeId && <ScheduleBoard serviceTreeId={service.ServiceTreeId} /> }
    </ViewLayout>
  );

  return (
    <PageLayout
      breadCrumbItems={breadCrumbItems}
      pageTitle={intl.get(LocIds.AuditManager.OnboardedContinuousMonitoringSchedules)}>
      { bodyJsx }
    </PageLayout>
  );
};
